import { ENV } from '../ENV'

export class LocalStorageCache {
  static getItem<T>(key: string, initialValue: T):T {
    try {
      const storedItem = window.localStorage.getItem(key)

      if (storedItem) {
        const { value, timestamp, version } = JSON.parse(storedItem)
        if (Date.now() - timestamp <= ENV.CACHE_EXPIRATION && version === ENV.VERSION) {
          // Cache je stále platná, vrátíme ji
          return value
        } else {
          // Cache expirovala, smažeme ji
          window.localStorage.removeItem(key)
        }
      }
      // Cache expirovala nebo neexistuje, použijeme initialValue
      return initialValue
    } catch (error) {
      console.error(error)
      return initialValue
    }
  }

  static setItem<T>(key: string, valueToStore: T) {
    // try {
      const cacheData = {
        value: valueToStore,
        timestamp: Date.now(),
        version: ENV.VERSION,
      }
      window.localStorage.setItem(key, JSON.stringify(cacheData))
    // } catch (error) {
    //   console.error(error)
    // }
  }
}
