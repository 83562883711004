import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useUser } from '../../hooks/useUser'
import { selectSchemaIsV1, selectSubmForm, selectSubmMainInfo, selectSubmReq } from '../../redux/submissions/selectors'
import ButtonGov from '../Btns/ButtonGov'

const FormHeaderAdminInfo = () => {
  const [open, setOpen] = useState(false)
  const { submMain, isProfil } = useSelector(selectSubmMainInfo)
  const submReq = useSelector(selectSubmReq)
  const submForm = useSelector(selectSubmForm)
  const isV1 = useSelector(selectSchemaIsV1)

  const { isAdmin } = useUser()
  if (!isAdmin) {
    return null
  }

  const data = {
    owner: submMain.owner,
    organization: submMain.organization.split('/').pop(),
    mainForm: {
      createdAt: submMain.createdAt,
      updatedAt: submMain.updatedAt,
      updatedBy: submMain.updatedBy?.name + ' - ' + submMain.updatedBy?.id,
      relatedId: submMain.relatedId,
      isProfil: isProfil,
      vvz: isV1 ? 'V1' : 'V2',
      version: submMain.version,
    },
    editForm: {
      updatedAt: submForm.updatedAt,
      updatedBy: submForm.updatedBy?.name + ' - ' + submForm.updatedBy?.id,
      version: submForm.version,
    },
    zadostForm: {
      updatedAt: submReq.updatedAt,
      updatedBy: submReq.updatedBy?.name + ' - ' + submReq.updatedBy?.id,
      version: submReq.version,
    },
    mainData: submMain.data,
  }

  if (!open) {
    return (
      <ButtonGov size='small' onClick={() => setOpen(true)} variant='primary-outlined'>
        Metadata
      </ButtonGov>
    )
  }

  return (
    <div>
      <pre style={{ fontSize: 12 }}>{JSON.stringify(data, null, 2)}</pre>
    </div>
  )
}

export default FormHeaderAdminInfo
