import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { SLUG_FORM } from '../../api/EformsApi'
import FormBaseSchemas from '../../builder/forms/FormBaseSchemas'
import { iDataSchemas } from '../../builder/interfaces/iDataSchema'
import { iUIschema } from '../../builder/interfaces/iUiSchema'
import { selectUserSubjekt } from '../../redux/user/selectors'
import SchemaLoader from '../../services/SchemaLoader'
import Loading from '../Loading/Loading'
import uiSchemas from './schema.layout.json'
import dataSchemas from './schema.properties.json'

const WizardForm = () => {
  const orgSlug = useSelector(selectUserSubjekt)

  const [reqScehmaId, setReqScehmaId] = React.useState('')

  useEffect(() => {
    SchemaLoader.loadSchemaVersionBySlug(orgSlug, SLUG_FORM.ZADOST2)
      .then((res) => setReqScehmaId(res.id))
      .catch((err) => {
        console.error(err)
        setReqScehmaId('-')
      })
  }, [])

  if (!reqScehmaId) {
    return <Loading />
  }
  
  return (
    <div className='wizard-form'>
      <FormBaseSchemas
        dataSchemas={dataSchemas as iDataSchemas}
        uiSchemas={uiSchemas as iUIschema[]}
        formSchemaId={reqScehmaId}
        validationMode={'all'}
      />
    </div>
  )
}

export default WizardForm
