import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { ENV } from '../../ENV'
import {
  selectFormErrorActive,
  selectFormHidden,
  selectFormReqErrorActive,
  selectFormReqHidden,
} from '../../redux/formErrors/selectors'
import { selectFormArrayLentgh } from '../../redux/formTree/formTreeSelectors'
import { setFormTree, setFormTreeIsBigDataArrayLength, setFormTreeReq } from '../../redux/formTree/formTreeSlice'
import { useAppDispatch } from '../../redux/store'
import { equalJson } from '../../utils/equalJson'
import { useFormRenderContext } from '../context/RenderFormContext'
import { createFormTree } from '../utils/createFormTree'

const FormTreeWatch = () => {
  const { uiSchemas, isReq } = useFormRenderContext()
  const dispath = useAppDispatch()

  const errors = useSelector(isReq ? selectFormReqErrorActive : selectFormErrorActive, equalJson)
  const hidden = useSelector(isReq ? selectFormReqHidden : selectFormHidden, equalJson)

  const errorsPaths = useMemo(() => errors.map((err) => err.atribut), [errors])
  const hiddenPaths = useMemo(() => hidden?.map((err) => err.atribut) || [], [hidden])

  const [lastFormTreeStr, setLastFormTreeStr] = useState('')

  //  optimalizace - reakce jen na zmenu/pridani opakovacich secki => do reduxu pocet opakovani na path
  const formArrayLengths = useSelector(selectFormArrayLentgh)

  // TODO je potreba ukladat form tree do reduxu?
  useEffect(() => {
    const treeSize = Object.values(formArrayLengths).reduce((prev, cur) => prev + cur, 0)
    if (ENV.DEBUG_MODE) {
      console.log('start calculate create form tree', isReq ? 'REQ' : 'FORM', treeSize, formArrayLengths)
    }
    if (treeSize > ENV.FORM_TREE_SIZE_REPEATS_BIG) {
      dispath(setFormTreeIsBigDataArrayLength(treeSize))
    }
    const formTree = createFormTree(uiSchemas, formArrayLengths, errorsPaths, hiddenPaths)
    const newFormTreeStr = JSON.stringify(formTree)
    if (ENV.DEBUG_MODE) {
      console.log(
        'formTree DIFF',
        isReq ? 'REQ' : 'FORM',
        lastFormTreeStr !== newFormTreeStr ? 'DIFFERENT tree' : 'SAME tree', lastFormTreeStr, newFormTreeStr,
        formTree,
      )
    }
    // kdyz se nic nezmenilo tak se objekt nemeni
    if (lastFormTreeStr !== newFormTreeStr) {
      setLastFormTreeStr(newFormTreeStr)
      dispath(isReq ? setFormTreeReq(formTree) : setFormTree(formTree))
    }
  }, [formArrayLengths, uiSchemas, errorsPaths, hiddenPaths])

  return null
}

export default FormTreeWatch
