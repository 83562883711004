import React, { useEffect, useState } from 'react'
import { ChevronDown, ChevronLeft, ChevronRight } from 'react-bootstrap-icons'
import { useSelector } from 'react-redux'
import ButtonGov from '../../components/Btns/ButtonGov'
import Loading from '../../components/Loading/Loading'
import { useWindowSize } from '../../hooks/useWindowSize'
import { selectLoadFormData, selectLoadReqData } from '../../redux/formState/selectors'
import {
  selectForceToSection,
  selectFormIsFullInit,
  selectFormTree,
  selectFormTreeReq,
} from '../../redux/formTree/formTreeSelectors'
import { FormNavigationSection } from './FormNavigationSection'

const FormNavigation = () => {
  const { isMobile } = useWindowSize()
  const [open, setOpen] = useState(!isMobile)
  const [openNavForm, setOpenNavForm] = useState(true)
  const [openNavReq, setOpenNavReq] = useState(true)
  const isFullInit = useSelector(selectFormIsFullInit)

  const forceOpenToSection = useSelector(selectForceToSection)

  useEffect(() => {
    if (isMobile && open && forceOpenToSection) {
      setOpen(false)
    }
  }, [forceOpenToSection])

  const formTree = useSelector(selectFormTree)
  const formTreeReq = useSelector(selectFormTreeReq)

  const loadFormData = useSelector(selectLoadFormData)
  const loadReqData = useSelector(selectLoadReqData)

  const classes = ['formnavigation']
  classes.push(open ? 'formnav-open' : 'formnav-close')
  classes.push(isMobile ? 'formnavigationMobile' : 'formnavigationDesktop')

  if (!isFullInit) {
    return (
      <nav className={classes.join(' ')}>
        <Loading />
      </nav>
    )
  }

  return (
    <>
      {isMobile && (
        <button
          className='u-bg-color--white gov-button gov-button--primary-outlined gov-button--small formnavigationButton'
          onClick={() => setOpen(!open)}
          title={open ? 'Zavřít navigaci formuláře' : 'Otevřít navigaci formuláře'}
        >
          {open ? (
            <span
              aria-hidden='true'
              // className='gov-icon gov-icon--burger-menu'
              className='gov-icon gov-icon--cross'
              style={{ fontWeight: 'normal', fontSize: 15 }}
            ></span>
          ) : (
            <svg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' width='14' height='14' viewBox='0 0 50 50'>
              <path d='M 0 7.5 L 0 12.5 L 50 12.5 L 50 7.5 Z M 0 22.5 L 0 27.5 L 50 27.5 L 50 22.5 Z M 0 37.5 L 0 42.5 L 50 42.5 L 50 37.5 Z'></path>
            </svg>
          )}
        </button>
      )}
      <nav className={classes.join(' ')}>
        {!isMobile && (
          <ButtonGov
            title={open ? 'Skrýt navigaci formuláře' : 'Zobrazit navigaci formuláře'}
            className='gov-button gov-button--small formnav-btn'
            size='small'
            onClick={() => setOpen(!open)}
          >
            {open ? <ChevronLeft size={10} /> : <ChevronRight size={15} />}
          </ButtonGov>
        )}
        {open && (
          <>
            {loadFormData && !!formTree.length && (
              <ul className='navblock'>
                <li className='nav-title'>
                  Navigace formuláře
                  <ButtonGov size='small' onClick={() => setOpenNavForm(!openNavForm)}>
                    {openNavForm ? <ChevronDown size={15} /> : <ChevronRight size={15} />}
                  </ButtonGov>
                </li>

                {openNavForm &&
                  formTree.map((childTree, index) => <FormNavigationSection key={index} formTree={childTree} />)}
              </ul>
            )}

            {loadReqData && !!formTreeReq.length && (
              <ul className='navblock'>
                <li className='nav-title'>
                  Navigace žádosti
                  <ButtonGov size='small' onClick={() => setOpenNavReq(!openNavReq)}>
                    {openNavForm ? <ChevronDown size={15} /> : <ChevronRight size={15} />}
                  </ButtonGov>
                </li>

                {openNavReq &&
                  formTreeReq.map((childTree, index) => <FormNavigationSection key={index} formTree={childTree} />)}
              </ul>
            )}
          </>
        )}
      </nav>
    </>
  )
}

export default React.memo(FormNavigation)
