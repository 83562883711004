import { iUiSchemaRuleV1 } from './iUiSchemaRuleV1'

// export interface iUIschema extends UISchemaElement {
export interface iUIschemaV1 {
  type: string // typ visualni komponenty
  label?: string | boolean | any // nadpis pro element
  text?: string // text pro visualni element - jen pro Label, Description, SectionTitle
  scope?: string // mapovani na datove schema (#/CONTRACTING_BODY/ADDRESS_CONTRACTING_BODY)
  elements?: iUIschemaV1[] // child elementy
  exclude?: string[] // pole scopes, ktere se maji smazat pri zmene v tomto inputu
  scopeRef?: string //scope ke kteremu item patri, ale neni to datova polozka - pro label, description, sectiontitle
  translateKey?: string //klic pro defiici prekladu, sklada se jako scope+translateKey - pokud je prazdny pouzije se pouze scope nebo kombinace scopeRef + type
  options?: {
    showHelp?: boolean // zobrazit ikonu s napovedou
    likeType?: 'string' | 'number' | 'object' | 'array' // pri ulozeni se ulozi jako typ - datovy prvek je typu - jen pro radioboxy a checkboxy ktere - pouziti enable inputy/sekce
    minItems?: number // jen pro typ Array a ArrayInline - minimalni pocet prvku (0, 1, 5)
    maxItems?: number // jen pro typ Array a ArrayInline - maximlani pocet prvku (0, 1, 5)
    fixedValue?: string // jen pro type Hidden - pevne dana hodnota inputu
    arrayCounter?: boolean // jen pro type Hidden - automaticky cisluje podle opakovaci sekce ve ktere je zanoreny
    rowType?: 'bigSmall' | 'alignLeft' | 'alignRight' // zpusob vykresleni radku -  jen pro type Row
    enumId?: string // jen pro type Select - id enumu v eforms,
    radioTFInline?: boolean // jen pro RadioTrueFalse - zobrazeni na jednom radku
    radioColumns?: number // pocet sloupcu - jen pro Radio
    radioAlignLeft?: boolean //  zarovnat polozky do leva - jn pro Radio
    customEnum?: string[] // volitelne hodnoty - jen pro Radio
    aresInputMap?: string[] // mapovani vysledku z Ares - jen pro AresBtn
    aresInputRelativeDeep?: number // zanoreni relativniho scope vuci ico - jen pro AresBtn
    iamInputMap?: string[] // mapovani vysledku z Iam - jen pro IamBtn
    checkMultiSubScope?: string //scope na datovy prvek v ramci opakovani checkbox multi - jen pro CheckboxMulti
    checkMultiInline?: boolean // zobrazit moznosti do radku - jen pro CheckboxMulti
    readOnly?: boolean // visualni disable - jen pro text a radia
    textareaSingleLine?: boolean // jeden radek - jen pro textarea
    groupVirtual?: boolean // group bez grafickych elementu - jen pro group
    arrayVirtual?: boolean // array bez grafickych elementu - jen pro array
    descriptionEditNotif?: boolean // zvyrazneni description a jen pro editaci - jen pro description
  }

  rule?: iUiSchemaRuleV1
  allRules?: iUiSchemaRuleV1[] // pomocna promena ktera obsahuje pravidla zderivovana pro dane scope - vsechna zanorena a prizpusobena scope pro array
}

export const getUiSchemeLikeType = (uischema: any) => ({
  likeString: uischema?.options?.likeType === 'string',
  likeNumber: uischema?.options?.likeType === 'number',
  likeObject: uischema?.options?.likeType === 'object',
  likeArray: uischema?.options?.likeType === 'array',
})
