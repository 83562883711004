import { ControlProps, OwnPropsOfEnum, rankWith, uiTypeIs } from '@jsonforms/core'
import { changeLikeType } from '../../utils/changeLikeType'
import { withJsonFormsEnumPropsCustom } from '../../core/withJsonFormsEnumPropsCustom'
import DebugElement from '../../DebugElement'
import { iUIschemaV1 } from '../../interfaces/iUIschemaV1'
import InputGovLabel from '../../components/InputGovLabel'
import WrapInputGov from '../../components/WrapInputGov'
import { UiElTypesInput } from '../uiElTypes'

const GovInputRadio = (props: ControlProps & OwnPropsOfEnum) => {
  const {
    data,
    handleChange,
    path,
    label,
    uischema,
    id,
    errors,
    visible,
    enabled,
    options,
  } = props

  const uiOptions = (uischema as iUIschemaV1).options
  const readOnly = uiOptions?.readOnly
  const disabled = !enabled || readOnly

  const columnCount = uiOptions?.radioColumns || 1
  const radioAlignLeft = uiOptions?.radioAlignLeft
  const customEnum: string[] | undefined = uiOptions?.customEnum

  const actOptions = customEnum
    ? customEnum.map((v) => ({ value: v, label: options?.find(({ value }) => value === v)?.label }))
    : options

  const change = (value: string) => {
    changeLikeType(value, uischema, path, handleChange)
  }

  const onClick = (value: string) => {
    if (data === value) {
      handleChange(path, undefined)
    }
  }

  if (!visible) {
    return null
  }

  return (
    <div id={uischema.scope}>
      <WrapInputGov uischema={uischema} path={path} custom error={errors}>
        <DebugElement data={props} />
        {label && <InputGovLabel inputId={''} label={label} noLeftPadding />}
        <div
          className='radioClasic'
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: radioAlignLeft ? 30 : undefined,
          }}
        >
          {actOptions?.map(({ label, value }, index) => (
            <div key={index} style={{ width: radioAlignLeft ? 'auto' : 100 / columnCount + '%' }}>
              <WrapInputGov custom>
                <input
                  id={id + '_' + value}
                  type='radio'
                  checked={data === value}
                  value={value}
                  onChange={(e) => change(value)}
                  disabled={disabled}
                  className='gov-form-control__radio'
                  key={value}
                  onClick={() => onClick(value)}
                  aria-disabled={disabled ? 'true' : 'false'}
                  aria-labelledby={id + '_' + value}
                />
                <InputGovLabel inputId={id + '_' + value} label={label} withIndicator />
              </WrapInputGov>
            </div>
          ))}
        </div>
      </WrapInputGov>
    </div>
  )
}

export default withJsonFormsEnumPropsCustom(GovInputRadio)

export const govInputRadioTester = rankWith(3, uiTypeIs(UiElTypesInput.Radio))
