import { createSelector } from '@reduxjs/toolkit'
import { convertDataSchemaEnums } from '../../builder_v1/convertors/convertDataSchemaEnums'
import { convertDataSchemaMergeValidations } from '../../builder_v1/convertors/convertDataSchemaMergeValidations'
import { iSchemaDatasV1 } from '../../builder_v1/interfaces/iSchemaDataV1'
import { findAllScopeToLabels } from '../../builder_v1/validations/findAllScopeToLabels'
import { RootState } from '../rootReducer'
import { selectSubmMainInfo } from './selectors'

export const selectSchemaFullV1 = (state: RootState) => ({
  properties: state.submissions.formSchema?.schema.properties as iSchemaDatasV1,
  layout: state.submissions.formSchema?.layout.elements as any,// iUIschemaV1,
  validations: {}, // TODO tate.submissions.formSchema?.layout.validations
})
export const selectSchemaFullReqV1 = (state: RootState) => ({
  properties: state.submissions.formSchemaReq?.schema.properties as iSchemaDatasV1,
  layout: state.submissions.formSchemaReq?.layout.elements as any,// iUIschemaV1,
  validations: {}, // TODO tate.submissions.formSchemaReq?.layout.validations
})

export const selectSchemaScopeToLabels = createSelector(
  selectSchemaFullV1,
  selectSubmMainInfo,
  (schemaFull, submMainInfo) => findAllScopeToLabels(submMainInfo.formType, schemaFull?.layout),
)
export const selectSchemaScopeToLabelsReq = createSelector(selectSchemaFullReqV1, (schemaFullReq) =>
  findAllScopeToLabels('', schemaFullReq?.layout),
)
export const selectSchemaModifEnums = createSelector(selectSchemaFullV1, (schemaFull) =>
  convertDataSchemaEnums(schemaFull?.properties),
)
export const selectSchemaModifEnumsReq = createSelector(selectSchemaFullReqV1, (schemaFullReq) =>
  convertDataSchemaEnums(schemaFullReq?.properties),
)
export const selectSchemaWithValidation = createSelector(
  selectSchemaModifEnums,
  selectSchemaFullV1,
  (schemaData, schemaFull) => convertDataSchemaMergeValidations(schemaData, schemaFull?.validations),
)
export const selectSchemaWithValidationReq = createSelector(
  selectSchemaModifEnumsReq,
  selectSchemaFullReqV1,
  (schemaDataReq, schemaFullReq) => convertDataSchemaMergeValidations(schemaDataReq, schemaFullReq?.validations),
)
