import {
  ControlProps,
  EnumOption,
  enumToEnumOptionMapper,
  getI18nKeyPrefix,
  getTranslator,
  JsonFormsState,
  OwnPropsOfControl,
  OwnPropsOfEnum,
} from '@jsonforms/core'
import { JsonFormsStateContext, withJsonFormsContext } from '@jsonforms/react'
import React, { ComponentType, useMemo } from 'react'
import { iUIschemaV1 } from '../interfaces/iUIschemaV1'
import {
  CustomPropsOfControl,
  CustomStatePropsOfControl,
  mapDispatchToControlPropsCustom,
  mapStateToControlPropsCustom,
} from './withJsonFormsControlPropsCustom'

export interface CustomPropsOfEnum extends Omit<OwnPropsOfEnum, 'uischema'> {
  uischema: iUIschemaV1
}

export const withJsonFormsEnumPropsCustom = (
  Component: ComponentType<ControlProps & CustomPropsOfEnum>,
  memoize = true,
): ComponentType<OwnPropsOfControl & CustomPropsOfEnum> =>
  withJsonFormsContext(withContextToEnumPropsCustom(memoize ? React.memo(Component) : Component))

const withContextToEnumPropsCustom =
  (Component: ComponentType<ControlProps & CustomPropsOfEnum>): ComponentType<OwnPropsOfControl & CustomPropsOfEnum> =>
  ({ ctx, props }: JsonFormsStateContext /*& ControlProps & CustomPropsOfEnum*/) => {
    const stateProps = ctxToEnumControlPropsCustom(ctx, props)
    // const dispatchProps = ctxDispatchToControlProps(ctx.dispatch)
    const excludes = (stateProps.uischema as iUIschemaV1).exclude
    const dispatchProps = useMemo(
      () => mapDispatchToControlPropsCustom(ctx.dispatch, excludes),
      [ctx.dispatch, excludes],
    )

    return <Component {...props} {...dispatchProps} {...stateProps} />
  }

export const ctxToEnumControlPropsCustom = (ctx: JsonFormsStateContext, props: CustomPropsOfEnum) => {
  const enumProps = mapStateToEnumControlPropsCustom({ jsonforms: { ...ctx } }, props)
  /**
   * Make sure, that options are memoized as otherwise the component will rerender for every change,
   * as the options array is recreated every time.
   */
  // const options = useMemo(() => enumProps.options, [props.options, enumProps.schema, ctx.i18n?.translate])
  const options = enumProps.options
  return { ...enumProps, options }
}

export const mapStateToEnumControlPropsCustom = (
  state: JsonFormsState,
  ownProps: CustomPropsOfControl & CustomPropsOfEnum,
): CustomStatePropsOfControl & CustomPropsOfEnum => {
  const props: CustomStatePropsOfControl = mapStateToControlPropsCustom(state, ownProps)

  const options: EnumOption[] =
    ownProps.options ||
    props.schema.enum?.map((e) =>
      enumToEnumOptionMapper(e, getTranslator()(state), getI18nKeyPrefix(props.schema, props.uischema, props.path)),
    ) ||
    (props.schema.const && [
      enumToEnumOptionMapper(
        props.schema.const,
        getTranslator()(state),
        getI18nKeyPrefix(props.schema, props.uischema, props.path),
      ),
    ])
  return {
    ...props,
    options,
  }
}
