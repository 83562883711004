export const ENV = {
  EFORMS_API_URL: process.env.REACT_APP_EFORMS_API_URL!,
  FE_URL: process.env.REACT_APP_FE_URL!,
  EFORMS_ADMIN_TOKEN: process.env.REACT_APP_EFORMS_ADMIN_TOKEN!,
  EFORMS_DEV_ORG_SLUG: process.env.REACT_APP_EFORMS_DEV_ORG_SLUG!,
  VERSION: process.env.REACT_APP_VERSION,
  REF_ENV: process.env.REACT_APP_REF_ENV === 'true',
  DEBUG_MODE: process.env.REACT_APP_DEBUG_MODE === 'true' || window.location.hash.includes('debug'),
  UPLOAD_MAX_FILES_SIZE: 19 * 1024 * 1024,
  CACHE_EXPIRATION: parseFloat(process.env.REACT_APP_CACHE_EXPIRATION_HOURS!) * 60 * 60 * 1000, // hodina v milisekundách
  EFORM_ATTACH_TYPE: 'form',
  BASE_LOGIN: process.env.REACT_APP_BASE_LOGIN === 'true',
  MAINTENANCE_MODE: process.env.REACT_APP_MAINTENANCE_MODE === 'true' && !window.location.hash.includes('fullmode'),
  URL_TED_FORM: 'https://ted.europa.eu/cs/notice/-/detail/',
  FORM_TREE_SIZE_REPEATS_BIG: 300,
  FORM_TREE_SIZE_REPEATS_EXTREME: 600,
  FORM_MAX_TOGGLE_LEVEL: 3,
  FORM_MAX_STICKY_LEVEL: 2, // duplikace v custom_new.scss
  FORM_MAX_ARROW_LEVEL: 1,
  FORM_TITLE_HEIGHT: 45,
  EFORMS_FORM_GROUP_VZ: 'vz',
  EFORMS_FORM_GROUP_PROFIL: 'profil'
}
