import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import EformsApi, { formGroupBySlug } from '../../api/EformsApi'
import { WORKFLOW_PLACES } from '../../enums/enumWorkflowPlaces'
import { FORM_TYPES as FT } from '../../enums_v1/enumFormType'
import { selectSubmMainInfo } from '../../redux/submissions/selectors'
import LoadingInline from '../Loading/LoadingInline'

const FormHeaderPosibleReplaced = () => {
  const { submMain, submMainData, formType, formSlug } = useSelector(selectSubmMainInfo)

  const posibleReplaced =
    submMain.workflowPlaceCode === WORKFLOW_PLACES.UVEREJNENO_VVZ &&
    [FT.F03, FT.F06, FT.F13, FT.F25, FT.F16, FT.F17, FT.F18, FT.F19].includes(formType)
  // && !submMainData.evCisloVvzSouvisejicihoFormulare

  const [replaced, setReplaced] = useState<boolean>()

  const formGroup = formGroupBySlug(formSlug)
  useEffect(() => {
    if (posibleReplaced) {
      EformsApi.submission.getSubmissionsSearch({
        formGroup: formGroup,
        form: formSlug,
        page: 1,
        limit: 1,
        workflowPlace: WORKFLOW_PLACES.UVEREJNENO_VVZ,
        'data.evCisloVvzSouvisejicihoFormulare': submMain.variableId,
        'data.druhFormulare': submMainData.druhFormulare,
      })
        .then((res) => {
          if (res.data.length) {
            setReplaced(true)
          } else {
            setReplaced(false)
          }
        })
        .catch((err) => {
          setReplaced(false)
          toast.error('Chyba vyhledání navazujícího formuláře')
        })
    }
  }, [submMain, submMainData, formType])

  if (posibleReplaced && replaced === undefined) {
    return <LoadingInline />
  }
  if (!replaced) {
    return null
  }
  return (
    <>
      {' '}
      (<strong style={{ color: 'var(--gov-color-error)' }}>ZNEPLATNĚNÝ</strong>)
    </>
  )
}

export default FormHeaderPosibleReplaced
