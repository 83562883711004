import {
  ArrayLayoutProps,
  JsonFormsState,
  mapDispatchToArrayControlProps,
  Resolve,
  StatePropsOfArrayLayout,
} from '@jsonforms/core'
import { ctxDispatchToControlProps, JsonFormsStateContext, withJsonFormsContext } from '@jsonforms/react'
import React, { ComponentType, useMemo } from 'react'
import { iUIschemaV1 } from '../interfaces/iUIschemaV1'
import { CustomPropsOfControl, mapStateToControlPropsCustom } from './withJsonFormsControlPropsCustom'

export interface CustomStatePropsOfArrayLayout extends Omit<StatePropsOfArrayLayout, 'uischema'> {
  uischema: iUIschemaV1
}

export const withJsonFormsArrayLayoutPropsCustom = (
  Component: ComponentType<ArrayLayoutProps>,
  memoize = true,
): ComponentType<CustomPropsOfControl> =>
  withJsonFormsContext(withContextToArrayLayoutPropsCustom(memoize ? React.memo(Component) : Component))

const withContextToArrayLayoutPropsCustom =
  (Component: ComponentType<ArrayLayoutProps>): ComponentType<CustomPropsOfControl> =>
  ({ ctx, props }: JsonFormsStateContext /* & ArrayLayoutProps*/) => {
    // ({ ctx, props }: any) => {
    const arrayLayoutProps = ctxToArrayLayoutPropsCustom(ctx, props)
    // const dispatchProps = ctxDispatchToArrayControlPropsCustom(ctx.dispatch)
    const dispatchProps = {
      ...ctxDispatchToControlProps(ctx.dispatch),
      ...useMemo(() => mapDispatchToArrayControlProps(ctx.dispatch as any), [ctx.dispatch]),
    }
    return <Component {...props} {...arrayLayoutProps} {...dispatchProps} />
  }

export const ctxToArrayLayoutPropsCustom = (ctx: JsonFormsStateContext, props: CustomPropsOfControl) =>
  mapStateToArrayLayoutPropsCustom({ jsonforms: { ...ctx } }, props)

export const mapStateToArrayLayoutPropsCustom = (
  state: JsonFormsState,
  ownProps: CustomPropsOfControl,
): CustomStatePropsOfArrayLayout => {
  const { path, schema, uischema, errors, ...props } = mapStateToControlPropsCustom(state, ownProps)

  const resolvedSchema = Resolve.schema(schema, 'items', props.rootSchema)

  return {
    ...props,
    path,
    uischema,
    schema: resolvedSchema,
    data: props.data ? props.data.length : 0,
    errors: errors,
    // minItems: schema.minItems,
  }
}
