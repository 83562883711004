import { JsonForms } from '@jsonforms/react'
import isEqual from 'lodash/isEqual'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import Loading from '../components/Loading/Loading'
import { FORM_TYPE_REQ } from '../enums_v1/enumFormType'
import { setFormData, setFormDataRequest } from '../redux/form/formSlice'
import { selectFormData, selectFormDataRequest } from '../redux/form/selectorsFormV1'
import { selectFormError, selectFormErrorReq } from '../redux/form/selectotsErrors'
import { clearIsValid } from '../redux/formState/formStateSlice'
import { selectFormWithRequest } from '../redux/formState/selectors'
import { useAppDispatch } from '../redux/store'
import { selectSubmMainInfo, selectSubmSchemasFormId, selectSubmSchemasReqId } from '../redux/submissions/selectors'
import {
  selectSchemaFullReqV1,
  selectSchemaFullV1,
  selectSchemaWithValidation,
  selectSchemaWithValidationReq,
} from '../redux/submissions/selectorsV1s'
import { calculateDataClearVisible } from './convertors/calculateDataClearVisible'
import { calculateDataSchemaArrayScopes } from './convertors/calculateDataSchemaArrayScopes'
import { calculateDataScopeNoPublic } from './convertors/calculateDataScopeNoPublic'
import { calculateDataWithDefault } from './convertors/calculateDataWithDefault'
import { calculateDataWithUiDefault } from './convertors/calculateDataWithUiDefault'
import { calculateSchemaDefaultValues } from './convertors/calculateSchemaDefaultValues'
import { calculateUiSchemaDefaultValues } from './convertors/calculateUiSchemaDefaultValues'
import { convertUiSchemaInheritRules } from './convertors/convertUiSchemaInheritRules'
import { rederersInput } from './elements/input/rederersInput'
import { rederersLayout } from './elements/layout/rederersLayout'
import { rederersVisual } from './elements/visual/rederersVisual'
import { FormTypeContexProvider } from './FormTypeContext'
import { resolveRules } from './rules/resolveRules'
import { translatePath } from './translate/translate'
import ajv from './validations/ajv'

const renderers = [
  ...rederersVisual, //
  ...rederersLayout,
  ...rederersInput,
]

interface iBuilder {
  isRequest?: boolean
}

const BuilderV1 = ({ isRequest }: iBuilder) => {
  const dispatch = useAppDispatch()

  const submMain = useSelector(selectSubmMainInfo)
  const formType = isRequest ? FORM_TYPE_REQ : submMain.formType
  const disabled = !submMain.isDraft

  const submReqSchemaId = useSelector(selectSubmSchemasReqId)
  const submFormSchemaId = useSelector(selectSubmSchemasFormId)
  const formSchema = isRequest ? submReqSchemaId : submFormSchemaId

  const schemaFull = useSelector(isRequest ? selectSchemaFullReqV1 : selectSchemaFullV1)
  const arrayScopes = useMemo(
    () => (schemaFull?.properties ? calculateDataSchemaArrayScopes(schemaFull.properties) : []),
    [schemaFull],
  )
  const defaultValues = useMemo(
    () => calculateSchemaDefaultValues(schemaFull?.validations, schemaFull?.properties, arrayScopes),
    [schemaFull, arrayScopes],
  )

  const schemaUi = useMemo(
    () => (schemaFull?.layout ? convertUiSchemaInheritRules(schemaFull.layout) : undefined),
    [schemaFull],
  )

  const defaultUiValues = useMemo(
    () => calculateUiSchemaDefaultValues(schemaUi, schemaFull?.properties, arrayScopes),
    [schemaUi, schemaFull, arrayScopes],
  )

  const data = useSelector(isRequest ? selectFormDataRequest : selectFormData)
  const errors = useSelector(isRequest ? selectFormErrorReq : selectFormError)
  const schemaWithValidation = useSelector(isRequest ? selectSchemaWithValidationReq : selectSchemaWithValidation)

  const noPublicScopes = useMemo(
    () => calculateDataScopeNoPublic(schemaWithValidation?.properties || {}),
    [schemaWithValidation],
  )

  const withReq = useSelector(selectFormWithRequest)

  const setData = (data: any) => {
    const rulesResult = resolveRules(data, schemaFull)
    const { visibleEnabledScopesSome } = rulesResult

    const dataWithDefaults = calculateDataWithDefault(data, defaultValues)
    // poradi dulezite - smaze default data ktera jsou bez pravidel
    const changedDataBefore = calculateDataClearVisible(dataWithDefaults, visibleEnabledScopesSome)
    const changedData = calculateDataWithUiDefault(changedDataBefore, defaultUiValues)

    if (!isEqual(data, changedData)) {
      // console.log('save clear data with defaults', data, '->', changedData)
      data = changedData
    }

    if (isRequest) {
      dispatch(setFormDataRequest(data))
    } else {
      dispatch(setFormData(data))
    }
    dispatch(clearIsValid())
  }
  // const [locale, setLocale] = useState<'cs' | 'en'>('cs')
  // const translation = useMemo(() => translator(locale), [locale]);
  const translate = useMemo(() => translatePath(formType), [formType])

  if (!schemaUi || !formSchema) {
    return <Loading />
  }

  return (
    <FormTypeContexProvider formType={formType} formSchema={formSchema} isRequest={isRequest}>
      <JsonForms
        readonly={disabled}
        ajv={ajv}
        // i18n={{locale:'cs', translate:i18n.t}}
        i18n={{ translate: translate as any }}
        schema={schemaWithValidation}
        uischema={schemaUi as any}
        config={{ noPubliScopes: disabled && !withReq ? noPublicScopes : [] }}
        data={data}
        renderers={renderers}
        onChange={({ data, errors }) => {
          setData(data)
        }}
        additionalErrors={errors as any}
        validationMode={'NoValidation'}
      />
    </FormTypeContexProvider>
  )
}

export default BuilderV1
