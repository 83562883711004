import { pathsMappings1_8to1_13 } from './pathsMappings1_8to1_13'

export interface iPathMapping {
  from: string
  to: string
}

export const pathsMappings = [
  {
    fromVersion: '1.8.0',
    toVersion: '1.8.2',
    pathsMapping: [],
  },
  {
    fromVersion: '1.8.2',
    toVersion: '1.13.0',
    pathsMapping: pathsMappings1_8to1_13,
  },
]
