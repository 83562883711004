import add from 'date-fns/add'
import isAfter from 'date-fns/isAfter'
import isBefore from 'date-fns/isBefore'
import path from 'path-browserify'
import { get } from 'react-hook-form'
import { RegisterOptions } from 'react-hook-form/dist/types/validator'
import { scopeFullToPath } from '../../utils/scopeModifs'
import { iDataSchema } from '../interfaces/iDataSchema'
import { iDateValid } from '../interfaces/iSchemaValidations'
import ajv, { SCHEMA_DATA_FORMAT } from './ajv'

const calculateValidDateRef = (dateValid: iDateValid, formValues: any, elScope: string) => {
  const refScope = !dateValid.scope
    ? undefined
    : dateValid.scope.startsWith('#')
    ? dateValid.scope
    : path.join(elScope, dateValid.scope)

  const dateRefVal = refScope ? get(formValues, scopeFullToPath(refScope)) : undefined
  if (refScope && !dateRefVal) {
    return undefined
  }
  const refDate = dateRefVal ? new Date(dateRefVal) : new Date()
  return add(refDate, {
    days: dateValid?.diffDays,
    months: dateValid?.diffMonths,
  })
}

export const createRules = (
  scope: string | undefined,
  disabled: boolean,
  dataSchema?: iDataSchema,
): RegisterOptions | undefined => {

  if (disabled || !dataSchema || !scope || (!dataSchema?.validations && !dataSchema?.pattern && !dataSchema?.format)) {
    return undefined
  }
  const path = scopeFullToPath(scope)

  const required = dataSchema?.validations?.required
  const pattern = dataSchema?.pattern
  const format = dataSchema?.format
  const requiredOrOneOf = dataSchema?.validations?.requiredOrOneOf
  const evalVal = dataSchema?.validations?.eval
  const { gt: dateValidGt, lt: dateValidLt } = dataSchema?.validations?.date || {}

  // TODO dat jen klice ktere se pouzivaji
  return {
    pattern: pattern ? { value: new RegExp(pattern), message: 'Nepovolená hodnota' } : undefined,
    validate: {
      required: required
        ? (value) => {
            if (value === null || value === undefined || value === '') {
              return 'Položka je povinná'
            }
          }
        : () => true,
      format: format
        ? (value) => {
            if (value && Object.values(SCHEMA_DATA_FORMAT).includes(format)) {
              const avjSchema = {
                type: 'string',
                format: format,
              }
              const res = ajv.validate(avjSchema, value)
              if (!res) {
                return 'Chybný formát'
              }
            }
          }
        : () => true,
      requiredOrOneOf: requiredOrOneOf
        ? (value, formValues) => {
            const parentPath = path.split('.').slice(0, -1).join('.')
            const parentData = get(formValues, parentPath)
            if (!value && !requiredOrOneOf.some((orOneOf) => parentData[orOneOf])) {
              return path.endsWith(requiredOrOneOf[0]) ? 'Vyplňte jednu z položek' : ' '
            }
          }
        : () => true,
      evalVal: evalVal
        ? (value, formValues) => {
          /* eslint-disable-next-line no-eval */
            const ret = eval(evalVal)
            if (ret) {
              return ret
            }
          }
        : () => true,
      dateGt: dateValidGt
        ? (value, formValues) => {
            if (!value) {
              return true
            }
            const dateRef = calculateValidDateRef(dateValidGt, formValues, scope)
            if (dateRef && !isAfter(new Date(value), dateRef)) {
              return dateValidGt?.errMsg || 'Datum musí být větší'
            }
          }
        : () => true,
      dateLt: dateValidLt
        ? (value, formValues) => {
            if (!value) {
              return true
            }
            const dateRef = calculateValidDateRef(dateValidLt, formValues, scope)
            if (dateRef && !isBefore(new Date(value), dateRef)) {
              return dateValidLt?.errMsg || 'Datum musí být menší'
            }
          }
        : () => true,
    },
  }
}
