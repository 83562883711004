import { ControlProps, rankWith, uiTypeIs } from '@jsonforms/core'
import { NumericFormat } from 'react-number-format'
import { DATA_TYPE } from '../../../enums_v1/enumDataType'
import { withJsonFormsControlPropsCustom } from '../../core/withJsonFormsControlPropsCustom'
import DebugElement from '../../DebugElement'
import { iUIschemaV1 } from '../../interfaces/iUIschemaV1'
import InputGovLabel from '../../components/InputGovLabel'
import WrapInputGov from '../../components/WrapInputGov'
import { SCHEMA_DATA_FORMAT } from '../../validations/ajv'
import { UiElTypesInput } from '../uiElTypes'

const TEL_PATTERN = /^(\+(\d{1,3}(\s(\d{1,}\s?)?)?)?)(\/\s?(\+(\d{1,3}(\s(\d{1,}\s?)?)?)?)?)?$/
const TEL_PREFIX = '+420 '
const formatTelNumber = (value: string) => {
  if (value.length === 1 && value !== '+') {
    value = TEL_PREFIX + value
  }
  if (value.length > 4 && !value.includes(' ')) {
    value = value.substring(0, 4) + ' ' + value.substring(4)
  }
  return value
}

const GovInputText = (props: ControlProps) => {
  const {
    data,
    handleChange,
    path,
    label,
    description,
    schema,
    uischema,
    id,
    errors,
    visible,
    enabled,
    required,
  } = props

  const uiOptions = (uischema as iUIschemaV1).options
  const readOnly = uiOptions?.readOnly
  const disabled = !enabled || readOnly

  const schemaType = Array.isArray(schema.type) ? schema.type[0] : schema.type
  const isNumber = schemaType === DATA_TYPE.number
  const isInteger = schemaType === DATA_TYPE.integer

  const format = (schema as any)?.validations?.format

  const change = (value: string) => {
    if (value === '') {
      handleChange(path, undefined)
    } else {
      handleChange(path, value)
    }
  }

  const isTelNumber = [SCHEMA_DATA_FORMAT.tel].includes(format)

  const changeTelNumber = (value: string) => {
    if (!value) {
      change(value)
      return
    }
    value = formatTelNumber(value)
    const numbers = value.split('/')
    if (numbers[1]) {
      value = numbers[0] + '/' + (numbers[1][0] === ' ' ? ' ' : '') + formatTelNumber(numbers[1].trim())
    }
    if (value.match(TEL_PATTERN)) {
      change(value.slice(0,100)) // max delka 100 znaku
    }
  }

  const value = isNumber ? (isNaN(data) ? '' : data) : data || ''

  if (!visible) {
    return null
  }

  const elId = id + '_' + schema.type

  const renderInput = () => {
    if (isInteger) {
      return (
        <NumericFormat
          value={value}
          onValueChange={(values) => {
            const num = parseInt(values.value)
            handleChange(path, isNaN(num) ? undefined : num)
          }}
          className={'gov-form-control__input'}
          id={elId}
          disabled={disabled}
          allowNegative={false}
          thousandSeparator={' '}
          // decimalSeparator={','}
          decimalScale={0}
        />
      )
    } else if (isNumber) {
      return (
        <NumericFormat
          value={value}
          onValueChange={(values) => {
            const num = parseFloat(values.value)
            handleChange(path, isNaN(num) ? undefined : num)
          }}
          className={'gov-form-control__input'}
          id={elId}
          disabled={disabled}
          allowNegative={false}
          thousandSeparator={' '}
          decimalSeparator={','}
        />
      )
    } else {
      return (
        <input
          id={elId}
          className='gov-form-control__input'
          type='text'
          disabled={disabled}
          value={value}
          onChange={(e: any) => (isTelNumber ? changeTelNumber(e.target.value) : change(e.target.value))}
          aria-required={required ? 'true' : 'false'}
          aria-disabled={disabled ? 'true' : 'false'}
          aria-labelledby={elId}
        />
      )
    }
  }

  return (
    <WrapInputGov
      uischema={uischema}
      value={isNumber || isInteger ? value.toString() : value}
      description={description}
      error={errors}
      path={path}
    >
      <DebugElement data={props} />
      {renderInput()}
      <InputGovLabel label={label} inputId={elId} required={required} />
    </WrapInputGov>
  )
}

export default withJsonFormsControlPropsCustom(GovInputText)

export const govInputTester = rankWith(3, uiTypeIs(UiElTypesInput.Text))
