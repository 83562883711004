import { toast } from 'react-toastify'
import EformsApi from '../../api/EformsApi'
import { convertXmlWithSchema } from '../../builder_v1/convertors/convertXmlWithSchema'
import { formTypesToXmlRoot } from '../../enums_v1/formTypesToXmlRoot'
import { iSubmissionDataHlavni } from '../../interfaces/iEformsSubmission'
import SchemaLoader from '../../services/SchemaLoader'

const EFORM_FILE_SUBTYPE_XML = 'xml-vvz'
const EFORM_FILE_SUBTYPE_XML_PUBLIC = 'xml-vvz-public'
const EFORM_FILE_SUBTYPE_XML_REQ = 'xml-zadost'

export const loadXmlFile = (resMain: iSubmissionDataHlavni, dataSchema?: any, isReq?: boolean) => {
  // ziskani XML prilohy s daty
  const submVersionId = resMain.submissionVersion?.split('/')[3]
  let formRootEl = isReq ? '' : formTypesToXmlRoot(resMain.data.druhFormulare, resMain.data.verzeXsd)
  const FILE_SUBTYPE = isReq ? EFORM_FILE_SUBTYPE_XML_REQ : EFORM_FILE_SUBTYPE_XML

  return EformsApi.submissionAttach.getAttachments({ submissionVersion: submVersionId })
    .then((res) => {
      let xmlFile = res.find((file) => file.subtype === FILE_SUBTYPE)
      if (!xmlFile && !isReq) {
        xmlFile = res.find((file) => file.subtype === EFORM_FILE_SUBTYPE_XML_PUBLIC)
      }
      if (xmlFile) {
        return EformsApi.submissionAttach.getAttachmentContentPublic(xmlFile.publicId)
          .then((resFile: Blob) => {
            return resFile
              .text()
              .then(async (fileContent) => {
                if (!dataSchema) {
                  const resSchema = await SchemaLoader.loadSchemaVersionById(resMain.data.souvisejiciFormSchemaId)
                  dataSchema = resSchema.schema.properties
                }
                const corectData = convertXmlWithSchema(formRootEl, fileContent, dataSchema)
                return corectData
              })
              .catch((e) => {
                toast.error('Nepodařilo se načíst data z XML souboru')
              })
          })
          .catch((e) => {
            toast.error('Nepodařilo se načíst přílohu s daty')
          })
      } else {
        toast.error('U formuláře není vhodný xml soubor')
      }
    })
    .catch((e) => {
      toast.error('Nepodařilo se načíst přílohy')
    })
}
