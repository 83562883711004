import React, { useState } from 'react'
import EformsApi from '../../api/EformsApi'
import { useModal } from '../../hooks/useModal'
import { reloadData } from '../../redux/global/globalSlice'
import { useAppDispatch } from '../../redux/store'
import AlertError from '../Alert/AlertError'
import AlertSuccess from '../Alert/AlertSuccess'
import ButtonGov from '../Btns/ButtonGov'
import Loading from '../Loading/Loading'

const ModalDeleteForm = () => {
  const dispatch = useAppDispatch()
  const { closeModal, dataDeleteForm } = useModal()
  const mainFormId = dataDeleteForm!

  const [sentLoading, setSentLoading] = useState(false)
  const [sentOk, setSentOk] = useState(false)
  const [sentError, setSentError] = useState('')

  const clickDelete = () => {
    setSentLoading(true)
    //TODO smazat jeste childs od formulare - edit zadost a edit podani
    EformsApi.submission.deleteForm(mainFormId)
      .then(() => {
        setSentOk(true)
        setTimeout(() => {
          dispatch(reloadData())
        }, 1000)
      })
      .catch((err) => {
        setSentError(err)
      })
      .finally(() => {
        setSentLoading(false)
      })
  }

  const clickCancel = () => {
    closeModal()
  }

  if (sentLoading) {
    return <Loading />
  }
  if (sentError) {
    return <AlertError msg='Chyba smazání formuláře. Zkuste to prosím později' />
  }
  if (sentOk) {
    return <AlertSuccess msg='Formulář byl úspěšně smazán.' />
  }

  return (
    <>
      <div className='u-mb--50'>
        Opravdu chcete smazat tento rozpracovaný formulář?
        <br/>
        V případě smazání bude formulář odstraněn ze systému a nelze ho obnovit.
      </div>

      <ButtonGov variant='primary' className='u-me--20' onClick={clickDelete}>
        Smazat
      </ButtonGov>

      <ButtonGov variant='primary-outlined' onClick={clickCancel}>
        Zrušit
      </ButtonGov>
    </>
  )
}

export default ModalDeleteForm
