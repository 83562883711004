import _ from 'lodash'
import { ENV } from '../ENV'
import { iPathMapping, pathsMappings } from './pathsMappings'

const resolveTargetPath = (fromTemplate: string, toTemplate: string, realPath: string) => {
  // Rozdělení podle ".[]"
  const fromParts = fromTemplate.split('.[].')
  const toParts = toTemplate.split('.[].')

  // Vytvoření regexu pro zachycení indexů z realPath podle fromTemplate
  // const regexPattern = fromParts.map(_.escapeRegExp).join('\\.\\[(\\d+)\\]\\.');
  const regexPattern = fromParts.map(_.escapeRegExp).join('\\.(\\d+)\\.')
  const match = realPath.match(new RegExp(regexPattern))

  if (!match) return toTemplate // Pokud nejsou indexy, vrátíme původní toTemplate

  const indices = match.slice(1) // Extrahujeme jen indexy

  // Sestavení nové cesty podle toTemplate
  // return _.reduce(toParts, (acc, part, i) => acc + part + (_.get(indices, i) ? `.[${indices[i]}].` : ''), '');
  return _.reduce(toParts, (acc, part, i) => acc + part + (_.get(indices, i) ? `.${indices[i]}.` : ''), '')
}

function findMappingPaths(data: any, fromTemplate: string) {
  const result: string[] = []
  // Rozdělit fromTemplate podle ".[]." (najdeme úrovně, kde jsou pole)
  const fromParts = fromTemplate.split('.[].')

  // Rekurzivní funkce pro procházení objektu podle fromParts
  function traverse(currentData: any, currentPath: string, depth: number) {
    if (depth >= fromParts.length) {
      // Přidáme cestu do výsledku **pouze pokud aktuální data nejsou undefined nebo null**
      if (!_.isNil(currentData)) {
        result.push(currentPath.replace(/^\./, '')) // Odstraníme úvodní tečku
      }
      return
    }

    const part = fromParts[depth]

    if (_.isArray(currentData)) {
      // Pokud je currentData pole, projdeme všechny prvky
      currentData.forEach((item, index) => {
        // traverse(item, `${currentPath}.[${index}]`, depth);
        traverse(item, `${currentPath}.${index}`, depth)
      })
    } else if (_.has(currentData, part)) {
      // Pokud existuje klíč, pokračujeme dál
      traverse(_.get(currentData, part), `${currentPath}.${part}`, depth + 1)
    }
  }

  // Spustíme rekurzi od kořene
  traverse(data, '', 0)
  return result
}

// Hlavní funkce s jedním průchodem
function findAllMappings2(data: any, mappings: iPathMapping[]) {
  const results: iPathMapping[] = []
  mappings.forEach(({ from: fromTemplate, to: toTemplate }) => {
    const fromPaths = findMappingPaths(data, fromTemplate)

    fromPaths.forEach((fromPath) => {
      const targetPath = resolveTargetPath(fromTemplate, toTemplate, fromPath)
      results.push({
        from: fromPath,
        to: targetPath,
      })
    })
  })

  return results
}

// function findAllMappings(data: any, mappings: iPathMapping[]) {
//   // Příprava mappingu pro rychlé filtrování a vyhledávání
//   const preparedMappings = mappings.map(({ from, to }) => ({
//     fromTemplate: from,
//     toTemplate: to,
//     regex: new RegExp(`^${_.escapeRegExp(from).replace(/\\\[\\]/g, '\\d+')}$`),
//   }))
//   const mappingsSimplePaths = mappings.map(({ from }) => from.replace(/\.\[?\d+]?/g, '').replace(/\.\[]\./g, '.'))
//   const results: iPathMapping[] = []
//
//   function traverse(node: any, currentPath = '') {
//     if (_.isObject(node)) {
//       _.forOwn(node, (value, key) => {
//         const newPath = Array.isArray(node) ? `${currentPath}.${key}` : currentPath ? `${currentPath}.${key}` : key
//
//         const simplifiedPath = newPath.replace(/\.\[?\d+]?/g, '').replace(/\.\[]\./g, '.')
//         const hasMapping = mappingsSimplePaths.some((simplePath) => simplePath.startsWith(simplifiedPath))
//         // console.log('newPath', newPath, simplifiedPath,  hasMapping, simplePaths)
//
//         if (hasMapping) {
//           preparedMappings.forEach(({ regex, fromTemplate, toTemplate }) => {
//             if (regex.test(newPath)) {
//               const targetPath = resolveTargetPath(fromTemplate, toTemplate, newPath)
//               results.push({
//                 from: newPath,
//                 to: targetPath,
//               })
//             }
//           })
//           traverse(value, newPath)
//         }
//       })
//     }
//   }
//   traverse(data)
//   return results
// }

export const convertFormDataMapping = (data: any, pathMapping: iPathMapping[]) => {
  const result = _.cloneDeep(data)
  const matchedPaths = findAllMappings2(data, pathMapping)
  if (ENV.DEBUG_MODE) {
    console.log('matchedPaths', matchedPaths)
  }
  // _.set(result, 'Amatched', matchedPaths)

  matchedPaths.forEach(({ from, to }) => {
    const fromValue = _.get(data, from)
    _.unset(result, from)
    _.set(result, to, fromValue)

    // Kontrola, zda cílová cesta končí indexem [0]
    // if (/\.\d+$/.test(to)) {
    //   // Cíl má být pole
    //   const targetParentPath = to.replace(/\.\d+$/, '')
    //   // _.set(result,'A', {targetParentPath, fromValue, from, to})
    //
    //   const targetArray = _.get(result, targetParentPath, [])
    //
    //   // Zajistíme, že cílová cesta bude polem
    //   if (!_.isArray(targetArray)) {
    //     _.set(result, targetParentPath, [])
    //   }
    //
    //   // TODO jiny index nez 0 ?
    //   _.set(result, targetParentPath, [fromValue])
    //
    //   // // Nastavení hodnoty do pole na příslušný index
    //   // const indexMatch = to.match(/\[(\d+)]$/);
    //   // const targetIndex = indexMatch ? parseInt(indexMatch[1], 10) : 0;
    //   // _.set(data, to, fromValue);
    // } else {
    //   // Pokud cílová cesta NENÍ pole, nastavíme hodnotu přímo
    //   _.set(result, to, fromValue)
    // }
  })
  return result
}

export const convertFormDataToVersion = (data: any, sdkVerOld: string, sdkVerNew: string) => {
  if (ENV.DEBUG_MODE) {
    console.log('convert', sdkVerOld, sdkVerNew, data)
  }
  let currentVersion = sdkVerOld
  let transformedData = _.cloneDeep(data)

  const versionMap = new Map(pathsMappings.map((mapping) => [mapping.fromVersion, mapping]))

  while (currentVersion !== sdkVerNew) {
    if (ENV.DEBUG_MODE) {
      console.log('maping', currentVersion, sdkVerNew)
    }

    const mapping = versionMap.get(currentVersion)
    if (!mapping) {
      console.error(`No mapping found from ${currentVersion}`)
      throw new Error(`No mapping found from ${currentVersion}`)
    }

    transformedData = convertFormDataMapping(transformedData, mapping.pathsMapping)
    if (ENV.DEBUG_MODE) {
      console.log('after convert', currentVersion, mapping.toVersion, _.cloneDeep(transformedData))
    }
    currentVersion = mapping.toVersion
  }

  return transformedData
}
