import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import EformsApi, { FORM_GROUP, SLUG_FORM } from '../../api/EformsApi'
import ExportApi from '../../api/ExportApi'
import { useModal } from '../../hooks/useModal'
import AlertError from '../Alert/AlertError'
import AlertSuccess from '../Alert/AlertSuccess'
import ButtonGov from '../Btns/ButtonGov'
import Captcha from '../Captcha/Captcha'
import InputGov from '../Inputs/InputGov'
import Loading from '../Loading/Loading'

const ModalExport = () => {
  const { t } = useTranslation()
  const [email, setEmail] = useState('')
  const [errorEmail, setErrorEmail] = useState('')

  const [sentLoading, setSentLoading] = useState(false)
  const [sentOk, setSentOk] = useState(false)
  const [sentError, setSentError] = useState('')

  const { closeModal, dataExport } = useModal()

  useEffect(() => {
    if (email && !/\S+@\S+\.\S+/.test(email)) {
      setErrorEmail(t('modalNotif.err_email', 'Zadejte platnou e-mailovou adresu'))
    } else {
      setErrorEmail('')
    }
  }, [email, t])

  const send = () => {
    setSentLoading(true)
    EformsApi.others
      .exportRequest({ formGroup: FORM_GROUP.VZ, form: SLUG_FORM.HLAVNI, ...dataExport!.filter })
      .then((response) => {
        return ExportApi.export(email, response.token)
          .then(() => {
            setSentOk(true)
          })
          .catch((err) => {
            setSentError(err)
          })
      })
      .catch((err) => {
        setSentError(err)
      })
      .finally(() => {
        setSentLoading(false)
      })
  }

  if (sentLoading) {
    return <Loading />
  }
  if (sentError) {
    return (
      <AlertError msg={t('modalNotif.err_odeslani', 'Chyba odeslání žádosti o export. Zkuste to prosím později')} />
    )
  }
  if (sentOk) {
    return <AlertSuccess msg={t('modalNotif.uspesne_odeslana', 'Žádost o export úspěšně odeslána.')} />
  }

  return (
    <Captcha>
      <p>Na uvedenou adresu vám bude zaslán e-mail s exportem dat.</p>

      <div className='u-mb--50'>
        <InputGov
          type='email'
          name='email'
          label={t('modalNotif.email', 'E-mailová adresa')}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={errorEmail}
        />
      </div>

      <ButtonGov variant='primary' className='u-me--20' onClick={send} disabled={!email || !!errorEmail}>
        {t('modalNotif.odeslat', 'Odeslat')}
      </ButtonGov>
      <ButtonGov variant='primary-outlined' onClick={closeModal}>
        {t('modalNotif.zavrit', 'Zavřít')}
      </ButtonGov>
    </Captcha>
  )
}

export default ModalExport
