import React, { useState } from 'react'
import EformsApi from '../../api/EformsApi'
import { useModal } from '../../hooks/useModal'
import { reloadData } from '../../redux/global/globalSlice'
import { useAppDispatch } from '../../redux/store'
import { canAdminChangeWorkflow } from '../../utils/canAdminChangeWorkflow'
import AlertError from '../Alert/AlertError'
import AlertSuccess from '../Alert/AlertSuccess'
import ButtonGov from '../Btns/ButtonGov'
import Loading from '../Loading/Loading'

const ModalChangeWorkflow = () => {
  const dispatch = useAppDispatch()
  const [sentLoading, setSentLoading] = useState(false)
  const [sentOk, setSentOk] = useState(false)
  const [sentError, setSentError] = useState('')

  const { closeModal, dataContract } = useModal()
  if (!dataContract) {
    return null
  }
  const formId = dataContract?.id
  const transition = canAdminChangeWorkflow(dataContract)
  if (!transition) {
    return null
  }

  const clickChangeWorkflow = (toPlace: string) => {
    setSentLoading(true)
    EformsApi.submission.updateSubmission(formId, {  workflowPlaceCode: toPlace })
      .then(() => {
        setSentOk(true)
        dispatch(reloadData())
      })
      .catch((err) => {
        setSentError(err)
      })
      .finally(() => {
        setSentLoading(false)
      })
  }

  if (sentLoading) {
    return <Loading />
  }
  if (sentError) {
    return <AlertError msg='Chyba změny stavu. Zkuste to prosím později' />
  }
  if (sentOk) {
    return <AlertSuccess msg='Stav úspěšně změněn.' />
  }

  return (
    <>
      <blockquote>Změnu stavu nelze vrátit!</blockquote>
      {transition.map(({ info, toPlace }) => (
        <>
          <div className='u-mb--50'>
            {info && (
              <p>
                Změna stavu z <strong>{info}.</strong>
              </p>
            )}
            {toPlace && (
              <ButtonGov variant='primary' onClick={() => clickChangeWorkflow(toPlace)}>
                Změnit stav
              </ButtonGov>
            )}
          </div>
        </>
      ))}
      <ButtonGov onClick={closeModal}>Zavřít</ButtonGov>
    </>
  )
}

export default ModalChangeWorkflow
