import { iUIschemaV1 } from '../interfaces/iUIschemaV1'
import { scopeFullToPath } from '../../utils/scopeModifs'

export const getTranlateLabelFullKey = (uischema: iUIschemaV1) => {
  const scope = uischema.scope || ''
  const translateKey = uischema.translateKey || ''
  const fullScope = translateKey ? (translateKey.startsWith('#') ? translateKey : scope + '/' + translateKey) : scope
  return scopeFullToPath(fullScope)
}

export const getTranlateTextFullKey = (uischema: iUIschemaV1) => {
  const scopeRef = uischema.scopeRef || ''
  const translateKey = uischema.translateKey || `_${uischema.type.toLowerCase()}`
  const fullScope = translateKey ? (translateKey.startsWith('#') ? translateKey : scopeRef + '/' + translateKey) : scopeRef
  return scopeFullToPath(fullScope)
}
