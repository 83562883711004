import React from 'react'
import { useElementContext } from '../../context/ElementContext'
import RenderElements from '../../renders/RenderElements'
import PartGovDescription from '../parts/PartGovDescription'
import PartGovErrorsBlock from '../parts/PartGovErrorsBlock'

const Row = () => {
  const { uiSchema, id } = useElementContext()

  const classes = ['custom-form__wrap', 'custom-form__row']

  if (uiSchema.options?.rowType === '60_40') {
    classes.push('custom-form__wrap--special-60')
  }
  if (uiSchema.options?.rowCssAlign === 'left') {
    classes.push('rowAlignLeft')
  }
  if (uiSchema.options?.rowCssAlign === 'right') {
    classes.push('rowAlignRight')
  }

  return (
    <div id={id} className={'rowSectionWrap'}>
      <PartGovDescription text={uiSchema.options?.description} />
      <PartGovErrorsBlock />
      <div className={classes.join(' ')}>{uiSchema.elements && <RenderElements uiSchemas={uiSchema.elements} />}</div>
    </div>
  )
}
export default Row
