import React from 'react'
import { useModal } from '../../../hooks/useModal'
import { useFormRenderContext } from '../../context/RenderFormContext'

interface iInputGovHelp {
  text: string
}

const PartGovHelp = ({ text }: iInputGovHelp) => {
  const { isReq, helpSimple } = useFormRenderContext()
  const { openModalHelpSimple, openModalHelpAdvance } = useModal()

  const clickHelp = () => {
    if (helpSimple) {
      openModalHelpSimple(text)
    } else {
      openModalHelpAdvance(text, isReq)
    }
  }

  return (
    <span className='gov-form-control__message inputHelp'>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a className='gov-link gov-link--small gov-modal__trigger' onClick={clickHelp}>
        <span className='gov-icon gov-icon--info'></span>
      </a>
    </span>
  )
}

export default React.memo(PartGovHelp)
