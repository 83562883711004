import { useRouteError } from 'react-router-dom'

export function ErrorRouteBoundary() {
  const error = useRouteError()
  // if (isRouteErrorResponse(error)) {
  //   if (error.status === 404) {
  //     return <div>This page doesn't exist!</div>
  //   }
  //
  //   if (error.status === 401) {
  //     return <div>You aren't authorized to see this</div>
  //   }
  //
  //   if (error.status === 503) {
  //     return <div>Looks like our API is down</div>
  //   }
  //
  //   if (error.status === 418) {
  //     return <div>🫖</div>
  //   }
  // }

  throw error
  /* eslint-disable-next-line no-unreachable */
  return null
  // return <div>Něco se pokazilo</div>;
}
