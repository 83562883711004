import { and, ControlProps, optionIs, or, OwnPropsOfEnum, rankWith, uiTypeIs } from '@jsonforms/core'
import { useCallback, useState } from 'react'
import AsyncSelect from 'react-select/async'
import { toast } from 'react-toastify'
import EformsApi from '../../../api/EformsApi'
import { useFormTypeContext } from '../../FormTypeContext'
import InputGovLabel from '../../components/InputGovLabel'
import WrapInputGov from '../../components/WrapInputGov'
import { withJsonFormsEnumPropsCustom } from '../../core/withJsonFormsEnumPropsCustom'
import DebugElement from '../../DebugElement'
import { UiElTypesInput } from '../uiElTypes'

const AUTOCOMPLETE_ENUMS_ID = [
  '431d9087-c799-4e49-b204-ef3758a21735', //CPV
  '99a086a3-31b0-436f-ad46-5f57c6b6b2bd', //CPV_SUB
  '31584800-5e68-44d6-a5e1-4d4c6d01b54b', //NUTS
]

function debounce(fn: any, delay = 250) {
  let timeout: any
  return (...args: any) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      fn(...args)
    }, delay)
  }
}

const GovInputAutocomplete = (props: ControlProps & OwnPropsOfEnum) => {
  const { data, handleChange, path, label, description, schema, uischema, id, errors, visible, enabled, required } =
    props

  const { formSchema } = useFormTypeContext()
  const enumId = uischema.options?.enumId

  const [openLabel, setOpenLabel] = useState(!!data)

  const onFocus = () => {
    setOpenLabel(true)
  }

  const onBlur = () => {
    setOpenLabel(false)
  }

  const promiseOptions = (inputValue: string) =>
    EformsApi.enumeration.getEnumerationsSearch({ formSchemaVersion: formSchema, enumerationGroup: enumId, limit: 150, label: inputValue }).then((data) =>
      data.map((opt: any) => ({ label: opt.code, value: opt.code })),
    )

  const promiseOptionsDebounce = useCallback(
    debounce((inputValue: string, callback: (options: any) => void) => {
      promiseOptions(inputValue).then((options) => callback(options))
    }, 500),
    [],
  )

  if (!visible) {
    return null
  }

  const elId = id + '_' + schema.type

  return (
    <WrapInputGov
      uischema={uischema}
      value={data}
      description={description}
      error={errors}
      path={path}
      openLabel={openLabel}
    >
      <DebugElement data={props} />
      {/* hack kvuli predani dat po rucnim vlozeni */}
      <div
        className='autocomplete-wrap'
        onPaste={(e) => {
          const val = e.clipboardData.getData('Text')
          handleChange(path, val)
          EformsApi.enumeration.getEnumerationsSearch({ formSchemaVersion: formSchema, enumerationGroup: enumId, limit: 150, label: val }).then((data) => {
            const valExist = data.some((opt: any) => opt.code === val)
            if (!valExist) {
              handleChange(path, undefined)
              toast.error('Chybná hodnota')
            }
          })
        }}
      >
        <AsyncSelect
          isDisabled={!enabled}
          isClearable={true}
          onFocus={onFocus}
          onBlur={onBlur}
          classNames={{
            container: () => 'selectAutocompleteContainer ' + (enabled ? '' : 'disabledAutocomplete'),
            control: () => 'gov-select',
            input: () => 'selectAutocompleteInput',
            dropdownIndicator: () => 'sa-dropdownIndicator',
            clearIndicator: () => 'sa-clearIndicator',
            indicatorSeparator: () => 'sa-indicatorSeparator',
            valueContainer: () => 'sa-valueContainer',
            loadingIndicator: () => 'sa-loadingIndicator',
            menu: () => 'sa-menu',
            menuList: () => 'sa-menuList',
            option: () => 'sa-option',
          }}
          inputId={elId}
          cacheOptions
          loadOptions={promiseOptionsDebounce}
          value={data ? { label: data, value: data } : { label: '', value: undefined }}
          onChange={(opt) => {
            handleChange(path, opt?.value)
          }}
          placeholder={false}
          noOptionsMessage={({ inputValue }) => (inputValue ? 'Žádné položky' : 'Zadejte text pro vyhledání')}
          loadingMessage={() => 'Vyhledávání...'}
          aria-required={required ? 'true' : 'false'}
          aria-disabled={!enabled ? 'true' : 'false'}
          aria-labelledby={elId}
        />
        <InputGovLabel label={label} inputId={elId} required={required} />
      </div>
    </WrapInputGov>
  )
}

export default withJsonFormsEnumPropsCustom(GovInputAutocomplete)

export const govInputAutocompleteTester = rankWith(
  50,
  and(
    uiTypeIs(UiElTypesInput.Select), //
    or(...AUTOCOMPLETE_ENUMS_ID.map((enumId) => optionIs('enumId', enumId))),
  ),
)
