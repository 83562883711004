import get from 'lodash/get'
import { iUiSchemaRuleV1, iUISchemaRuleCondition, RULE_EFFECT } from '../interfaces/iUiSchemaRuleV1'
import { scopeFullToPath, scopeWithoutRepeat } from '../../utils/scopeModifs'
import ajv from '../validations/ajv'
import { iSchemaRules } from './findAllRules'
import { modifRules } from './modifRules'

const applyCondition = (json: any, condition: iUISchemaRuleCondition): boolean => {
  if ('type' in condition) {
    return condition.type === 'AND'
      ? condition.conditions.every((c) => applyCondition(json, c))
      : condition.conditions.some((c) => applyCondition(json, c))
  }
  const ruleSchema = condition.schema
  const path = scopeFullToPath(condition.scope)
  const ruleData = get(json, path)
  const isValid = ajv.validate(ruleSchema, ruleData)
  return isValid
}
const applyRule = (json: any, rule: iUiSchemaRuleV1, effectEnable?: boolean) => {
  const ruleEffect = rule.effect
  const effectShow = effectEnable ? RULE_EFFECT.ENABLE : RULE_EFFECT.SHOW
  const effectHide = effectEnable ? RULE_EFFECT.DISABLE : RULE_EFFECT.HIDE

  const isValid = applyCondition(json, rule.condition)
  const visible = isValid ? effectShow === ruleEffect : effectHide === ruleEffect
  return visible
}

const applyRules = (json: any, rules: iUiSchemaRuleV1[], effectEnable?: boolean) => {
  if (!rules?.length) {
    return {
      all: true,
      some: true,
    }
  }
  // rozdeleni na davky dle ui elementu
  const rulesByUiEl: any = {}
  rules.forEach((rule: any) => {
    if (!(rule.uiElIndex in rulesByUiEl)) {
      rulesByUiEl[rule.uiElIndex] = []
    }
    rulesByUiEl[rule.uiElIndex].push(rule)
  })
  const rulesByUiElements: iUiSchemaRuleV1[][] = Object.values(rulesByUiEl)
  const resultsByUiElement = rulesByUiElements
    .filter((r) => r.length)
    .map((rulesByUiElement) => rulesByUiElement.every((rule: iUiSchemaRuleV1) => applyRule(json, rule, effectEnable)))
  return {
    all: resultsByUiElement.every((r) => r),
    some: resultsByUiElement.some((r) => r),
  }
}

export const isVisibleResolveAll = (json: any, scope: string, rulesAll: iSchemaRules) => {
  const simpleScope = scopeWithoutRepeat(scope)
  const rules = rulesAll[simpleScope]
  const rulesModif = modifRules(scope, rules)
  return isVisibleRules(json, rulesModif)
}

export const isVisibleRules = (json: any, rules: iUiSchemaRuleV1[]) => {
  const rulesVisible = rules?.filter((rule) => [RULE_EFFECT.SHOW, RULE_EFFECT.HIDE].includes(rule.effect))
  const rulesEnabled = rules?.filter((rule) => [RULE_EFFECT.ENABLE, RULE_EFFECT.DISABLE].includes(rule.effect))

  const visible = applyRules(json, rulesVisible, false)
  const enabled = applyRules(json, rulesEnabled, true)

  const visibleNoLikeType = applyRules(
    json,
    rulesVisible?.filter((r: any) => !r.likeType),
    false,
  )
  const enabledNoLikeType = applyRules(
    json,
    rulesEnabled?.filter((r: any) => !r.likeType),
    true,
  )

  return {
    enabled: enabled.all,
    visible: visible.all,
    enabledSome: enabled.some,
    visibleSome: visible.some,
    enableSomeNoLikeType: enabledNoLikeType.some,
    visibleSomeNoLikeType: visibleNoLikeType.some,
  }
}

