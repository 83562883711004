import { iUIschemaV1 } from '../interfaces/iUIschemaV1'
import { iUiSchemaRuleV1 } from '../interfaces/iUiSchemaRuleV1'

export const convertUiSchemaInheritRules = (uiSchema: iUIschemaV1, parentRules?: iUiSchemaRuleV1[]): iUIschemaV1 => {
  const allRules = [...(parentRules ? parentRules : [])]
  if (uiSchema.rule) {
    allRules.push(uiSchema.rule)
  }
  return {
    ...uiSchema,
    elements: uiSchema.elements ? uiSchema.elements.map((el) => convertUiSchemaInheritRules(el, allRules)) : undefined,
    allRules: allRules?.length ? allRules : undefined,
  }
}
