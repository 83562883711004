import get from 'lodash/get'
import { iSchemaDatasV1 } from '../interfaces/iSchemaDataV1'
import { scopeAddProperties, scopeFullToPath } from '../../utils/scopeModifs'

const SCOPE_REPEAT_CHAR = '%%%'
/*
najde scope vsech array elementu v ui schema
 */
export const calculateDataSchemaArrayScopes = (dataSchema: iSchemaDatasV1, scope = '#') => {
  const ret: string[] = []

  for (const key in dataSchema) {
    const thisScope = scope + '/' + key
    const schemaItem = dataSchema[key]
    if (schemaItem.items?.properties) {
      ret.push(...calculateDataSchemaArrayScopes(schemaItem.items.properties, thisScope))
      ret.push(thisScope) //az po tom vnitrim - kvuli poradi nahrazovani
    } else if (schemaItem.properties) {
      ret.push(...calculateDataSchemaArrayScopes(schemaItem.properties, thisScope))
    }
  }
  return ret
}

/*
 najde opakovani array a pokud je v datech tak vsechny jeho prvky => zanorit pro kazde opakovani
 */
const findExistDataPath = (pathParts: string[], data: any, path = '') => {
  const ret: string[] = []

  if (pathParts.length > 1) {
    const firstPart = pathParts[0]
    const subData = get(data, firstPart)
    if (subData && Array.isArray(subData)) {
      subData.forEach((subItem, index) => {
        const thisPath = (path ? path + '.' : '') + firstPart + '.' + index
        ret.push(...findExistDataPath(pathParts.slice(1), subItem, thisPath))
      })
    }
  } else {
    ret.push(path + '.' + pathParts[0])
  }
  return ret
}

export const scopeToExistDataPaths = (scope: string, data: any) => {
  const paths: string[] = []
  // zjisit jestli je scope z array
  const path = scopeFullToPath(scope)
  if (path.includes(SCOPE_REPEAT_CHAR)) {
    const parts = path.split('.' + SCOPE_REPEAT_CHAR + '.')
    paths.push(...findExistDataPath(parts, data))
  } else {
    paths.push(path)
  }
  return paths
}

export const scopeToScopeWithRepeat = (scope: string, arrayScopes: string[]) => {
  let scopeWithRepeat = scope
  arrayScopes.forEach((arrayScope: string) => {
    if (scopeWithRepeat !== arrayScope) { // nenahrazovat cele
      scopeWithRepeat = scopeWithRepeat.replace(arrayScope + '/', arrayScope + '/' + SCOPE_REPEAT_CHAR + '/')
    }
  })

  const dataSchemaPath = scopeFullToPath(
    scopeAddProperties(scopeWithRepeat.replaceAll('/' + SCOPE_REPEAT_CHAR, '.items').slice(2)),
  )

  return { scopeWithRepeat, dataSchemaPath }
}
