import React from 'react'
import { toast } from 'react-toastify'
import EformsApi from '../../api/EformsApi'
import { WORKFLOW_PLACES } from '../../enums/enumWorkflowPlaces'
import { useModal } from '../../hooks/useModal'
import { reloadData } from '../../redux/global/globalSlice'
import { useAppDispatch } from '../../redux/store'
import ButtonGov from '../Btns/ButtonGov'

const ModalConfirmCancelForm = () => {
  const dispatch = useAppDispatch()
  const { closeModal, dataConfirm } = useModal()
  if (!dataConfirm) {
    return null
  }
  const clickOk = () => {
    EformsApi.submission.updateSubmission(dataConfirm,{  workflowPlaceCode: WORKFLOW_PLACES.ZRUSENO })
      .then(() => {
        toast.success('Podání bylo zrušeno')
        dispatch(reloadData())
      })
      .catch((err) => {
        toast.error('Chyba zrušení podání')
      })
    closeModal()
  }

  const clickCancel = () => {
    closeModal()
  }

  return (
    <>
      <div className='u-mb--50'>
        Opravdu chcete zrušit tento rozpracovaný formulář?
        <br />V případě zrušení zůstane formulář dostupný v sekci "Moje vyhlášení" a lze ho obnovit a dále s ním
        pracovat.
      </div>

      <ButtonGov variant='primary' className='u-me--20' onClick={clickOk}>
        Potvrdit
      </ButtonGov>

      <ButtonGov variant='primary-outlined' onClick={clickCancel}>
        Zrušit
      </ButtonGov>
    </>
  )
}

export default ModalConfirmCancelForm
