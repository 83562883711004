import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import EformsApi from '../../api/EformsApi'
import { useEffectStart } from '../../hooks/useEffectStart'
import { useModal } from '../../hooks/useModal'
import { TRANSLATE_DOMAIN_LAYOUT } from '@qcm/eforms-client'
import { selectSubmSchemasFormId, selectSubmSchemasReqId } from '../../redux/submissions/selectors'
import ButtonGov from '../Btns/ButtonGov'
import Loading from '../Loading/Loading'

const ModalHelpAdvance = () => {
  const { closeModal, dataHelpAdvance } = useModal()

  const [loading, setLoading] = useState(false)
  const [helpText, setHelpText] = useState('')
  const [error, setError] = useState('')

  const schemaId = useSelector(dataHelpAdvance?.isReq ? selectSubmSchemasReqId : selectSubmSchemasFormId)

  useEffectStart(() => {
    const key = dataHelpAdvance?.key
    if (!key) {
      setError('Není nastavený klíč pro překlad.')
      return
    }
    setLoading(true)

    EformsApi.formSchema.getFormSchemaVersionTranslates({
      formSchemaVersion: schemaId!,
      domain: [TRANSLATE_DOMAIN_LAYOUT],
      key: key
    })
      .then((res) => {
        setHelpText(res[TRANSLATE_DOMAIN_LAYOUT][key])
      })
      .catch((e) => {
        setError('Nepodařilo se získat nápovědu pro tuto položku.')
      })
      .finally(() => setLoading(false))
  })

  if (!dataHelpAdvance) {
    return null
  }

  const clickCancel = () => {
    closeModal()
  }
  if (loading) {
    return <Loading />
  }
  return (
    <>
      <div className='u-mb--50' style={{ whiteSpace: 'pre-wrap' }}>
        {error ? <>{error}</> : helpText}
      </div>
      <ButtonGov variant='primary-outlined' onClick={clickCancel}>
        Zavřít
      </ButtonGov>
    </>
  )
}

export default ModalHelpAdvance
