import { iEformsFormSchemaVersion, TRANSLATE_DOMAIN_FORM, TRANSLATE_DOMAIN_LAYOUT } from '@qcm/eforms-client'
import { toast } from 'react-toastify'
import EformsApi from '../api/EformsApi'
import { combineFormTranslate, combineSchemaTranslate } from '../builder/utils/combineSchemaTranslate'
import { LocalStorageCache } from './LocalStorageCache'

class SchemaLoader {
  // loadSchema = (schemaIri: string) => {
  //   return EformsApi.getFormSchemaByIri(schemaIri).catch((err) => {
  //     toast.error('Nepodařilo se načíst schéma pro formulář')
  //     throw err
  //   })
  // }

  loadSchemaVersionById = (formSchemaVersionId: string) => {
    return EformsApi.formSchema.getFormSchemaVersion(formSchemaVersionId).catch((err) => {
      toast.error('Nepodařilo se načíst schéma pro formulář')
      throw err
    })
  }

  loadSchemaBySlug = (orgSlug: string, formSlug: string) => {
    return EformsApi.forms
      .getFormBySlug(orgSlug, formSlug)
      .then((form) => EformsApi.formSchema.getFormSchemaByIri(form.formSchema))
  }

  loadSchemaVersionBySlug = (orgSlug: string, formSlug: string) => {
    return this.loadSchemaBySlug(orgSlug, formSlug).then((schema) =>
      EformsApi.formSchema.getFormSchemaVersionByIri(schema.currentVersion),
    )
  }

  loadSchemaVersionWithTranslate = (schemaVersionIri: string) => {
    const formSchemaVersionId = schemaVersionIri.split('/').slice(-1)[0]
    return this.loadSchemaVersionByIdWithTranslate(formSchemaVersionId)
  }

  loadSchemaVersionByIdWithTranslate = (formSchemaVersionId: string) => {
    const storageKey = 'formSchemaVersion_' + formSchemaVersionId
    const formSchema = LocalStorageCache.getItem<iEformsFormSchemaVersion | undefined>(storageKey, undefined)
    if (formSchema) {
      return Promise.resolve(formSchema)
    }

    return this._loadSchemaVersionByIdWithTranslate(formSchemaVersionId).then((formSchema) => {
      LocalStorageCache.setItem(storageKey, formSchema)
      return formSchema
    })
  }

  _loadSchemaVersionByIdWithTranslate = (formSchemaVersionId: string) =>
    EformsApi.formSchema
      .getFormSchemaVersion(formSchemaVersionId)
      .catch((err) => {
        toast.error('Nepodařilo se načíst schéma pro formulář s překlady')
        throw err
      })
      .then(async (formSchema) => {
        if (!formSchema.metadata?.verzeXsd) {
          const translateData = await EformsApi.formSchema
            .getFormSchemaVersionTranslates({
              formSchemaVersion: formSchemaVersionId,
              domain: [TRANSLATE_DOMAIN_LAYOUT, TRANSLATE_DOMAIN_FORM],
            })
            .catch((err) => {
              console.error(err)
              toast.error('Nepodařilo se načíst překlady pro formulář')
            })
          if (translateData) {
            if (formSchema.layout.elements && translateData[TRANSLATE_DOMAIN_LAYOUT]) {
              formSchema.layout.elements = combineSchemaTranslate(
                formSchema.layout.elements,
                translateData[TRANSLATE_DOMAIN_LAYOUT],
              )
            }

            if (formSchema.layout.form && translateData[TRANSLATE_DOMAIN_FORM]) {
              formSchema.layout.form = combineFormTranslate(
                formSchema.layout.form,
                translateData[TRANSLATE_DOMAIN_FORM],
              )
            }
          }
        }
        return formSchema
      })
}

const SL = new SchemaLoader()
export default SL
