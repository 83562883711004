import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import setDefaultOptions  from 'date-fns/setDefaultOptions'
import cs from 'date-fns/locale/cs'
import en from 'date-fns/locale/en-GB'

import moment from 'moment'
import 'moment/locale/cs'
import React from 'react'

import { registerLocale, setDefaultLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import ReactDOM from 'react-dom/client'

import { Provider } from 'react-redux'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import App from './components/App/App'
import ErrorBoundaryVvz from './components/App/ErrorBoundaryVvz'
import './index.scss'
import store from './redux/store'
import './translations/i18n'

moment.locale('cs')
moment.updateLocale(moment.locale(), { invalidDate: '-' })

registerLocale('cs', cs)
registerLocale('en', en)
setDefaultLocale('cs')

setDefaultOptions({ locale: cs })

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // retryOnMount:true,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      // refetchOnReconnect: false,
      retry: false,
      // staleTime: twentyFourHoursInMs,
      // staleTime: Infinity,
    },
  },
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

// polyfill - replaceAll
/* eslint-disable no-extend-native */
if (!String.prototype.replaceAll) {
  String.prototype.replaceAll = function(str, newStr: any) {
    // If a regex pattern
    if (Object.prototype.toString.call(str).toLowerCase() === '[object regexp]') {
      return this.replace(str, newStr)
    }
    // If a string
    return this.replace(new RegExp(str, 'g'), newStr)
  }
}

root.render(
  // <React.StrictMode>
    <ErrorBoundaryVvz>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <ToastContainer />
          <App />
          {/*<ReactQueryDevtools initialIsOpen={false} />*/}
        </QueryClientProvider>
      </Provider>
    </ErrorBoundaryVvz>
  // </React.StrictMode>,
)

