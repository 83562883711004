import { GroupLayout, LayoutProps, rankWith, uiTypeIs } from '@jsonforms/core'
import { JsonFormsDispatch } from '@jsonforms/react'
import React from 'react'
import { iUIschemaV1 } from '../../interfaces/iUIschemaV1'
import { withJsonFormsLayoutPropsCustom } from '../../core/withJsonFormsLayoutPropsCustom'
import DebugElement from '../../DebugElement'
import { UiElTypesLayout } from '../uiElTypes'

export interface CustomLayoutProps extends LayoutProps {
  required?: boolean
  errors?: string
}

const GovLayoutGroupRender = (props: CustomLayoutProps) => {
  const { schema, uischema, renderers, enabled, visible, path, cells, label, required, errors } = props

  const groupLayout = uischema as GroupLayout

  const uiOptions = (uischema as iUIschemaV1).options
  const groupVirtual = uiOptions?.groupVirtual

  if (!visible) {
    return null
  }

  const inner =
    groupLayout.elements &&
    groupLayout.elements.map((child, index) => (
      <JsonFormsDispatch
        schema={schema}
        uischema={child}
        path={path}
        enabled={enabled}
        renderers={renderers}
        cells={cells}
        key={index}
      />
    ))

  if (groupVirtual) {
    return <>{inner}</>
  }

  return (
    <div className='custom-form u-mb--30'>
      <DebugElement data={props} />
      <h2 className={(errors ? 'error-ref-element' : '') + ' custom-form__title gov-title--delta'}>
        {label} {required ? '*' : ''}
      </h2>

      {inner}
    </div>
  )
}
export default withJsonFormsLayoutPropsCustom(GovLayoutGroupRender)

export const govLayoutGroupTester = rankWith(1000, uiTypeIs(UiElTypesLayout.Group))

