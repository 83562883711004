import { toast } from 'react-toastify'
import EformsApi, { SLUG_FORM } from '../api/EformsApi'
import { formTypeToFormSlugV2 } from '../enums/enumFormTypeV2'
import { iEformsSubmission, iSubmissionDataHlavni } from '../interfaces/iEformsSubmission'
import { clearIsValid, setFormLoadErr, setFormWithRequest } from '../redux/formState/formStateSlice'
import { AppThunk } from '../redux/store'
import {
  setFormSchema,
  setFormSchemaReq,
  setFormVersion,
  setLoading,
  setReqVersion,
  setSubmForm,
  setSubmReq,
} from '../redux/submissions/submissionsSlice'
import FormFullInit from '../services/FormFullInit'
import SchemaLoader from '../services/SchemaLoader'
import { ERR_MSG } from './utils/errorMsgs'

export const loadSubmissionJson = (resMain: iSubmissionDataHlavni): AppThunk => {
  return (dispatch) => {
    const formType = resMain.data.druhFormulare
    const editFormSlug = formTypeToFormSlugV2(formType)
    const promiseData = EformsApi.submission.getSubmissionsChildrenSearch({ submission: resMain.id, form: editFormSlug }).then(
      (resChilds: iEformsSubmission<any>[]) => {
        const resData = resChilds[0]
        if (resData) {
          dispatch(setSubmForm(resData))
          dispatch(clearIsValid())
          dispatch(setFormVersion(resData.version))
          FormFullInit.clear()
          // FormFullInit.setFormData(resData.data)
          return SchemaLoader.loadSchemaVersionWithTranslate(resData.formSchemaVersion).then((s) => {
            // FormFullInit.setFormDataSchema(s.schema.layout)
            dispatch(setFormSchema(s))
          })
        } else {
          throw new Error('NO EDIT FORM')
        }
      },
    )

    const promiseReq = EformsApi.submission.getSubmissionsChildrenSearch({ submission: resMain.id, form: SLUG_FORM.ZADOST2 })
      .then((resChilds: iEformsSubmission<any>[]) => {
        const resZadost = resChilds[0]
        if (resZadost) {
          dispatch(setSubmReq(resZadost))
          dispatch(setReqVersion(resZadost.version))
          return SchemaLoader.loadSchemaVersionWithTranslate(resZadost.formSchemaVersion).then((s) =>
            dispatch(setFormSchemaReq(s)),
          )
        } else {
          dispatch(setFormWithRequest(false))
        }
      })
      .catch((e) => {
        console.error(e)
        toast.error('Nepodařilo se načíst data žádosti formuláře')
      })

    Promise.all([promiseData, promiseReq])
      .then(() => {
        dispatch(setLoading(false))
      })
      .catch((err) => {
        console.error(err)
        dispatch(setFormLoadErr(ERR_MSG.UNKNOWN))
      })
  }
}
