import EformsApi from '../api/EformsApi'
import { iOption } from '../interfaces/iOption'
import { LocalStorageCache } from './LocalStorageCache'

class EnumLoader {
  promises: { [key: string]: Promise<iOption[]> } = {}
  memoryCache: { [key: string]: any } = {}

  async getByCode(formSchemaId: string, enumId: string, fieldValue: string): Promise<iOption[]> {
    const key = formSchemaId + '_' + enumId + '_' + fieldValue

    const cached = LocalStorageCache.getItem(key, undefined)
    if (cached) {
      return Promise.resolve(cached)
    }

    if (key in this.promises) {
      return this.promises[key]
    }
    const delay = this.calculateDelay()
    await this.delayExecution(delay)

    if (!(key in this.promises)) {
      this.promises[key] = EformsApi.enumeration.getEnumerationByCode({
        formSchemaVersion: formSchemaId,
        enumerationGroup: enumId,
        code: fieldValue
      }).then((res) => {
        const singles = [res].map((opt: any) => ({ label: opt.label, value: opt.code }))
        LocalStorageCache.setItem(key, singles)
        return singles
      })
    }
    return this.promises[key]
  }

  async search(formSchema: string, enumId: string, limit: number, query?: string): Promise<iOption[]> {
    const key = 'search_' + formSchema + '_' + enumId + '_' + query

    const cached = this.memoryCache[key]
    if (cached) {
      return Promise.resolve(cached)
    }
    if (key in this.promises) {
      return this.promises[key]
    }
    const delay = this.calculateDelay()
    await this.delayExecution(delay)

    if (!(key in this.promises)) {
      this.promises[key] = EformsApi.enumeration.getEnumerationsSearch({
        formSchemaVersion: formSchema,
        enumerationGroup: enumId,
        limit: limit,
        label: query
      }).then((data) => {
        const options: iOption[] = data.map((opt: any) => ({ label: opt.label, value: opt.code }))
        this.memoryCache[key] = options
        return options
      })
    }
    return this.promises[key]
  }

  private calculateDelay(): number {
    return Math.random() * 200
  }

  private async delayExecution(delay: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, delay))
  }
}

export default new EnumLoader()
