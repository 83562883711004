import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import EformsApi, { formGroupBySlug } from '../api/EformsApi'
import { LINKS } from '../components/App/LINKS'
import { useUser } from './useUser'

export const useNavigateToFormVariableId = (formSlug: string) => {
  const navigate = useNavigate()
  const [loadingLink, setLoading] = useState(false)
  const { isAdmin } = useUser()

  const formGroup = formGroupBySlug(formSlug)
  const navigateToFormVariableId = (variableId: string) => {
    setLoading(true)
    EformsApi.submission.getSubmissionsSearch({
      formGroup: formGroup,
      form: formSlug,
      page: 1,
      limit: 1,
      variableId: variableId,
    })
      .then((res) => {
        if (res.data.length) {
          navigate((isAdmin ? LINKS.admin : LINKS.vyhledatFormular) + '/' + res.data[0].id)
        } else {
          toast.error('Navazující formulář nenalezen')
        }
      })
      .catch((err) => {
        toast.error('Chyba hledání navazujícího formuláře')
      })
      .finally(() => {
        setLoading(false)
      })
  }
  return { navigateToFormVariableId, loadingLink }
}
