import { ControlElement } from '@jsonforms/core'
import { iUIschemaV1 } from '../interfaces/iUIschemaV1'

export const changeLikeType = (
  value: any,
  uischema: ControlElement,
  path: string,
  handleChange: (path: string, value: any) => void,
) => {
  const likeType = (uischema as iUIschemaV1).options?.likeType

  if (likeType === 'string') {
    handleChange(path, value ? '' : undefined)
  } else if (likeType === 'number') {
    handleChange(path, value ? 0 : undefined)
  } else if (likeType === 'object') {
    handleChange(path, value ? {} : undefined)
  } else if (likeType === 'array') {
    handleChange(path, value ? [] : undefined)
  } else {
    handleChange(path, value)
  }
}
