import { EformApiClient } from '@qcm/eforms-client'
import { ENV } from '../ENV'
import { interceptorRequestAuthToken } from './interceptorRequestAuthToken'
import { interceptorResponseAuthToken } from './interceptorResponseAuthToken'

export const FORM_GROUP = {
  VZ: ENV.EFORMS_FORM_GROUP_VZ,
  PROFIL: ENV.EFORMS_FORM_GROUP_PROFIL,
}

export const formGroupBySlug = (formSlug: string) => (formSlug === SLUG_FORM.PROFIL ? FORM_GROUP.PROFIL : FORM_GROUP.VZ)

export const SLUG_FORM = {
  HLAVNI: process.env.REACT_APP_EFORMS_SLUG_FORM_HLAVNI!,
  PROFIL: process.env.REACT_APP_EFORMS_SLUG_FORM_PROFIL!,
  ZADOST: process.env.REACT_APP_EFORMS_SLUG_FORM_ZADOST!,
  ZADOST_EDIT: process.env.REACT_APP_EFORMS_SLUG_FORM_ZADOST_EDIT!,
  ZADOST2: process.env.REACT_APP_EFORMS_SLUG_FORM_ZADOST2!,
}

EformApiClient.initApi(ENV.EFORMS_API_URL) // pokud neni pred aplikaci tak se nenacte spravne detail formulare
interceptorRequestAuthToken(EformApiClient.api)
interceptorResponseAuthToken(EformApiClient.api)

export default EformApiClient
