import { ControlProps, rankWith, uiTypeIs } from '@jsonforms/core'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import IamApi from '../../../api/IamApi'
import ButtonGov from '../../../components/Btns/ButtonGov'
import { selectUserName, selectUserSubjekt } from '../../../redux/user/selectors'
import { scopePartToPath } from '../../../utils/scopeModifs'
import { withJsonFormsControlPropsCustom } from '../../core/withJsonFormsControlPropsCustom'
import DebugElement from '../../DebugElement'
import { iUIschemaV1 } from '../../interfaces/iUIschemaV1'
import { UiElTypesInput } from '../uiElTypes'

const NO_INPUT_MAP = '-'

const IAM_DEFAULTS = [
  'OFFICIALNAME',
  'NATIONALID',
  'ADDRESS',
  'TOWN',
  'POSTAL_CODE',
  'COUNTRY/el_attrs/VALUE',
  'CONTACT_POINT',
  'E_MAIL',
  'PHONE',
]

const GovIamBtn = (props: ControlProps) => {
  const {
    handleChange,
    path,
    uischema,
    visible,
    enabled,
  } = props
  const [loading, setLoading] = useState(false)

  const subjekt = useSelector(selectUserSubjekt)
  const username = useSelector(selectUserName)

  const iamInputMap = (uischema as iUIschemaV1).options?.iamInputMap
  const mapName = scopePartToPath(iamInputMap?.[0] || IAM_DEFAULTS[0])
  const mapIco = scopePartToPath(iamInputMap?.[1] || IAM_DEFAULTS[1])
  const mapAddress = scopePartToPath(iamInputMap?.[2] || IAM_DEFAULTS[2])
  const mapTown = scopePartToPath(iamInputMap?.[3] || IAM_DEFAULTS[3])
  const mapPostalCode = scopePartToPath(iamInputMap?.[4] || IAM_DEFAULTS[4])
  const mapStateCode = scopePartToPath(iamInputMap?.[5] || IAM_DEFAULTS[5])
  const mapUserName = scopePartToPath(iamInputMap?.[6] || IAM_DEFAULTS[6])
  const mapEmail = scopePartToPath(iamInputMap?.[7] || IAM_DEFAULTS[7])
  const mapPhone = scopePartToPath(iamInputMap?.[8] || IAM_DEFAULTS[8])

  if (!visible || !enabled) {
    return null
  }

  const clickIamInfo = () => {
    setLoading(true)

    const parentPath = path + '.'
    const change = (mapEl: string, value: string) => {
      if (mapEl !== NO_INPUT_MAP) {
        handleChange(parentPath + mapEl, value)
      }
    }

    const promiseUser = IamApi.getUzivatelDetail(username).catch((err) => {
      toast.error('Nepodařilo se načíst data z profilu uživatele')
    })

    const promiseOrg = IamApi.getSubjektDetail(subjekt).catch((err) => {
      toast.error('Nepodařilo se načíst data z profilu organizace')
    })

    Promise.all([promiseUser, promiseOrg]).then(([userData, orgData]: any) => {
      change(mapUserName, `${userData.jmeno} ${userData.prijmeni}`)
      change(mapEmail, userData.email)
      change(mapPhone, userData.telefon)

      change(mapName, orgData.nazev)
      change(mapIco, orgData.ico)
      change(mapAddress, `${orgData.adresa?.ulice} ${orgData.adresa?.cisloDomovni}`)
      change(mapTown, orgData.adresa?.obec)
      change(mapPostalCode, orgData.adresa?.psc)
      change(mapStateCode, 'CZ')

      toast.success('Název a adresa načteny z profilu uživatele a organizace')
      setLoading(false)
    })
  }

  return (
    <div className='gov-form-control'>
      <DebugElement data={props} />
      <ButtonGov variant='primary' disabled={!enabled || loading} loading={loading} onClick={clickIamInfo}>
        Předvyplnit údaje podle profilu
      </ButtonGov>
    </div>
  )
}

export default withJsonFormsControlPropsCustom(GovIamBtn)

export const govIamBtnTester = rankWith(5, uiTypeIs(UiElTypesInput.IamBtn))
