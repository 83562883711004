import { ControlProps, OwnPropsOfEnum, rankWith, uiTypeIs } from '@jsonforms/core'
import { changeLikeType } from '../../utils/changeLikeType'
import { withJsonFormsEnumPropsCustom } from '../../core/withJsonFormsEnumPropsCustom'
import DebugElement from '../../DebugElement'
import { getUiSchemeLikeType, iUIschemaV1 } from '../../interfaces/iUIschemaV1'
import InputGovLabel from '../../components/InputGovLabel'
import WrapInputGov from '../../components/WrapInputGov'
import { UiElTypesInput } from '../uiElTypes'

const GovInputRadioAlone = (props: ControlProps & OwnPropsOfEnum) => {
  const {
    data,
    handleChange,
    path,
    label,
    schema,
    uischema,
    id,
    errors,
    visible,
    enabled,
  } = props

  const uiOptions = (uischema as iUIschemaV1).options
  const readOnly = uiOptions?.readOnly
  const disabled = !enabled || readOnly

  const value = uischema.scope
  const { likeNumber, likeString } = getUiSchemeLikeType(uischema)

  const change = (value: boolean) => {
    changeLikeType(value, uischema, path, handleChange)
  }

  const isChecked = !!data || (likeString && data === '') || (likeNumber && data === 0)

  const onClick = () => {
    if (isChecked) {
      handleChange(path, undefined)
    }
  }

  if (!visible) {
    return null
  }

  const elId = id + '_' + schema.type

  return (
    <div id={uischema.scope} className='radioAlone'>
      <WrapInputGov uischema={uischema} path={path} custom error={errors}>
        <DebugElement data={props} />
        <input
          id={elId}
          type='radio'
          checked={isChecked}
          value={value}
          onChange={(e) => change(e.target.checked)}
          disabled={disabled}
          className='gov-form-control__radio'
          key={value}
          onClick={() => onClick()}
          aria-disabled={disabled ? 'true' : 'false'}
          aria-labelledby={elId}
        />
        <InputGovLabel inputId={elId} label={label} withIndicator />
      </WrapInputGov>
    </div>
  )
}

export default withJsonFormsEnumPropsCustom(GovInputRadioAlone)

export const govInputRadioAloneTester = rankWith(3, uiTypeIs(UiElTypesInput.RadioAlone))
