import {
  getCells,
  getConfig,
  getData,
  getRenderers,
  getSchema,
  getTranslator,
  JsonFormsState,
  LabelProps,
  OwnPropsOfEnum,
  OwnPropsOfLabel,
  Resolve,
} from '@jsonforms/core'
import { JsonFormsStateContext, withJsonFormsContext } from '@jsonforms/react'
import React, { ComponentType } from 'react'
import { iUIschemaV1 } from '../interfaces/iUIschemaV1'
import { scopeFullToPath, scopeWithoutRepeat } from '../../utils/scopeModifs'
import { isVisibleRules } from '../rules/isVisibleRules'
import { getErrorsFromScope } from '../utils/getErrorsFromScope'
import { getSchemaItem } from '../utils/getSchemaItem'
import { getTranlateTextFullKey } from '../utils/getTranslateUiSchema'

export interface CustomPropsOfLabel extends Omit<OwnPropsOfLabel, 'uischema'> {
  uischema: iUIschemaV1
}

export const withJsonFormsLabelPropsCustom = (
  Component: ComponentType<LabelProps & OwnPropsOfEnum>,
  memoize = true,
): ComponentType<CustomPropsOfLabel> =>
  withJsonFormsContext(withContextToLabelPropsCustom(memoize ? React.memo(Component) : Component))

const withContextToLabelPropsCustom =
  (Component: ComponentType<LabelProps>): ComponentType<CustomPropsOfLabel> =>
  ({ ctx, props }: JsonFormsStateContext /*& LabelProps & OwnPropsOfLabel*/) => {
    const stateProps = ctxToLabelPropsCustom(ctx, props)
    return <Component {...props} {...stateProps} />
  }

export const ctxToLabelPropsCustom = (ctx: JsonFormsStateContext, ownProps: CustomPropsOfLabel) => {
  return mapStateToLabelPropsCustom({ jsonforms: { ...ctx } }, ownProps)
}

export const mapStateToLabelPropsCustom = (state: JsonFormsState, props: CustomPropsOfLabel) => {
  const { uischema } = props

  const rootData = getData(state)
  const allRules = uischema.allRules!
  const { visible } = isVisibleRules(rootData, allRules)

  const text = uischema.text
  const t = getTranslator()(state)
  const translateFullKey = getTranlateTextFullKey(uischema)
  const translateText = text !== undefined ? text : t(translateFullKey, translateFullKey)

  const rootSchema = getSchema(state)

  const resolvedSchema = getSchemaItem(rootSchema, uischema.scopeRef)
  const errors = getErrorsFromScope(state, uischema.scope)
  const required = (resolvedSchema as any)?.validations?.required

  const path = uischema?.scopeRef ? scopeFullToPath(uischema.scopeRef) : ''

  const data = Resolve.data(rootData, path)
  const config = getConfig(state)
  const simpleRefScope = scopeWithoutRepeat(uischema?.scopeRef || '')
  const isNoPublic =
    config?.noPubliScopes?.some((noPublicScope: string) => simpleRefScope.startsWith(noPublicScope)) && data === undefined

  const isHide = state.jsonforms.readonly && uischema.options?.descriptionEditNotif
  return {
    text: translateText,
    visible: visible && !isNoPublic && !isHide,
    config: getConfig(state),
    renderers: props.renderers || getRenderers(state),
    cells: props.cells || getCells(state),
    required: required,
    errors: errors.map((e) => e.message).join(', '),
    path: path,
    uiOptions: uischema.options
  }
}
