import { ArrayLayoutProps, composePaths, rankWith, UISchemaElement, uiTypeIs } from '@jsonforms/core'
import { JsonFormsDispatch } from '@jsonforms/react'
import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import ButtonGov from '../../../components/Btns/ButtonGov'
import { selectSubmMainInfo } from '../../../redux/submissions/selectors'
import { convertUiSchemaArray } from '../../convertors/convertUiSchemaArray'
import { withJsonFormsArrayLayoutPropsCustom } from '../../core/withJsonFormsArrayLayoutPropsCustom'
import DebugElement from '../../DebugElement'
import { iUIschemaV1 } from '../../interfaces/iUIschemaV1'
import WrapBlockGov from '../../components/WrapBlockGov'
import { UiElTypesLayout } from '../uiElTypes'

export const GovArrayInlineRenderer = (props: ArrayLayoutProps) => {
  const {
    visible,
    enabled,
    uischema,
    schema,
    renderers,
    cells,
    data,
    path,
    errors,
    addItem,
    removeItems,
  } = props

  const readOnly = !useSelector(selectSubmMainInfo).isDraft

  const defaultItemValue = {}

  const addItemCb = useCallback(
    (p: string, value: any) => {
      return addItem(p, value)()
    },
    [addItem],
  )

  const removeItemsCb = useCallback(
    (path: string, toDelete: number[]) => {
      return removeItems ? removeItems(path, toDelete)() : () => {}
    },
    [removeItems],
  )

  const elements: UISchemaElement[] = (uischema as any).elements
  const minUiItems: number = uischema.options?.minItems || 0
  const maxUiItems: number = uischema.options?.maxItems || undefined
  const isFull = maxUiItems && data >= maxUiItems

  if (!visible) {
    return null
  }
  
  const len = (enabled || data) ? data : minUiItems
  const toRender = Array(len)
    .fill(0)
    .map((_, indexRepeater) => {
      return (
        <div key={indexRepeater} style={{ display: 'flex', flexDirection: 'row', marginRight: 20 }}>
          {elements.map((child, index) => (
            <JsonFormsDispatch
              schema={schema}
              uischema={convertUiSchemaArray(child as iUIschemaV1, uischema.scope, indexRepeater) as any}
              path={composePaths(path, `${indexRepeater}`)}
              enabled={enabled}
              renderers={renderers}
              cells={cells}
              key={index}
            />
          ))}

          {indexRepeater >= minUiItems && enabled && (
            <div style={{ marginLeft: 5 }}>
              <ButtonGov size='small' variant='primary' onClick={() => removeItemsCb(path, [indexRepeater])}>
                &times;
              </ButtonGov>
            </div>
          )}
        </div>
      )
    })
  return (
    <WrapBlockGov path={path} error={errors}>
      <DebugElement data={props} />
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {toRender}
        {!isFull && !readOnly && (
          <div className='u-mb--30'>
            <ButtonGov disabled={!enabled} variant='primary' onClick={() => addItemCb(path, defaultItemValue)}>
              Vložit další
            </ButtonGov>
          </div>
        )}
      </div>
    </WrapBlockGov>
  )
}

export default withJsonFormsArrayLayoutPropsCustom(GovArrayInlineRenderer)

export const govLayoutArrayInlineTester = rankWith(1000, uiTypeIs(UiElTypesLayout.ArrayInline))

