import get from 'lodash/get'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import EformsApi from '../../api/EformsApi'
import {
  CZ_FORM_TYPES_V2,
  FORM_TYPES_V2 as FT2,
  FORM_TYPES_V2_NAMES,
  formTypeToFormSlugV2,
} from '../../enums/enumFormTypeV2'
import { useModal } from '../../hooks/useModal'
import { iOption } from '../../interfaces/iOption'
import { selectUserSubjekt } from '../../redux/user/selectors'
import { FormsCreator } from '../../services/FormsCreator'
import SchemaLoader from '../../services/SchemaLoader'
import { getErrorMessage } from '../../utils/errorMessage'
import { LINKS } from '../App/LINKS'
import ButtonGov from '../Btns/ButtonGov'
import InputGov from '../Inputs/InputGov'
import RadioInput from '../Inputs/RadioInput'
import SelectInput from '../Inputs/SelectInput'
import Loading from '../Loading/Loading'

const CREATE_TYPES = {
  NEW: 'new',
  EXIST: 'exist',
}
const optionsNewForm = [
  { value: CREATE_TYPES.NEW, label: 'Formulář zakládá novou zakázku' },
  { value: CREATE_TYPES.EXIST, label: 'Formulář se vztahuje k existující zakázce' },
]

const ONLY_ALTER_LABEL = [FT2.FCZ05, FT2.FCZ06]
const optionsNewFormAlternative = [
  { value: CREATE_TYPES.NEW, label: 'Formulář zakládá nový profil' },
  { value: CREATE_TYPES.EXIST, label: 'Formulář se vztahuje k existující zakázce/profilu' },
]

const ONLY_NEW = [
  FT2.F4,
  FT2.F5,
  FT2.F6,
  FT2.F7,
  FT2.F8,
  FT2.F9,
  FT2.F10,
  FT2.F11,
  FT2.F12,
  FT2.F13,
  FT2.F14,
  FT2.F22,
  FT2.F23,
  FT2.F24,
  FT2.FCZ05,
  FT2.FCZ07,
]
export const ONLY_EXIST = [FT2.F36, FT2.F37, FT2.F38, FT2.F39, FT2.F40, FT2.FCZ06]

const FORM_REPAIR_ENUM_PATH =
  'ND-Root.properties.ND-RootExtension.properties.ND-Changes.properties.ND-ChangeReason.properties.BT-140-notice.enum.key'

const ModalNewForm = () => {
  const navigate = useNavigate()
  const { closeModal, dataNewForm, dataNewRepairForm } = useModal()

  const isRepair = !!dataNewRepairForm

  const argFormType = dataNewForm!
  const isMultiNewFormType = Array.isArray(argFormType)
  const [selectNewFormType, setSelectNewFormType] = useState('')
  const formType = isMultiNewFormType ? selectNewFormType : argFormType

  const optionsMultiFormType = isMultiNewFormType
    ? argFormType.map((ft) => ({
        label: `${ft} - ${FORM_TYPES_V2_NAMES[ft]}`,
        value: ft,
      }))
    : []

  const [repairType, setRepairType] = useState('')

  const isOnlyNew = ONLY_NEW.includes(formType) && !isRepair
  const isOnlyExist = ONLY_EXIST.includes(formType) || isRepair
  const isOnlyAlterLabel = ONLY_ALTER_LABEL.includes(formType)

  const hasFormRepairType = !CZ_FORM_TYPES_V2.includes(formType)

  const [createType, setCreateType] = useState(
    isOnlyNew ? CREATE_TYPES.NEW : isOnlyExist || isRepair ? CREATE_TYPES.EXIST : '',
  )
  const [evCisloZakazky, setEvCisloZakazky] = useState(dataNewRepairForm?.evCisloZakazky || '')
  const [evCisloFormulare, setEvCisloFormulare] = useState(dataNewRepairForm?.evCisloFormulare || '')
  const [kodSouvisFormulare, setKodSouvisFormulare] = useState(dataNewRepairForm?.kodSouvisFormulare || '')

  const disableTextInput = createType !== CREATE_TYPES.EXIST

  const isNeedTedNum = dataNewRepairForm?.needTedNum
  const [tedNum, setTedNum] = useState('')
  const isValidTedNum = /^\d{4}\/S \d{3}-\d{6}$/.test(tedNum)

  const isCreateNew = createType === CREATE_TYPES.NEW
  const isFillInputs = !!evCisloZakazky && !!evCisloFormulare && !!kodSouvisFormulare
  const isSelectReapairType = !isRepair || !!repairType || !hasFormRepairType
  const isSelectFormType = !!formType
  const isFillTedNum = !isNeedTedNum || (tedNum && isValidTedNum)
  const isFillAllInput = isCreateNew || (isFillInputs && isSelectReapairType && isSelectFormType && isFillTedNum)

  const opts = isOnlyAlterLabel ? optionsNewFormAlternative : optionsNewForm
  const options = opts.filter((opt) =>
    isOnlyNew ? opt.value === CREATE_TYPES.NEW : isOnlyExist ? opt.value === CREATE_TYPES.EXIST : true,
  )

  const [sentLoading, setSentLoading] = useState(false)

  const [optionsRepairType, setOptionsRepairType] = useState<iOption[]>([])

  const orgSlug = useSelector(selectUserSubjekt)

  useEffect(() => {
    if (formType && dataNewRepairForm && hasFormRepairType) {
      setOptionsRepairType([])
      const formSlug = formTypeToFormSlugV2(formType)

      SchemaLoader.loadSchemaVersionBySlug(orgSlug, formSlug)
        .then((res) => {
          const enumKey = get(res.schema.properties, FORM_REPAIR_ENUM_PATH)

          return EformsApi.enumeration
            .getEnumerationsSearch({
              formSchemaVersion: res.id,
              enumerationGroup: enumKey,
            })
            .then((data) => {
              const options: iOption[] = data.map((opt: any) => ({ label: opt.label, value: opt.code }))
              setOptionsRepairType(options)
            })
        })
        .catch((err) => {
          toast.error('Nepodařily se načíst typy oprav')
        })
    }
  }, [formType])

  const changeCreateType = (type: string) => {
    setEvCisloFormulare('')
    setEvCisloZakazky('')
    setKodSouvisFormulare('')
    setCreateType(type)
  }

  if (!dataNewForm) {
    return null
  }
  const clickCreate = async () => {
    setSentLoading(true)

    try {
      const formsCreator = new FormsCreator(formType)
      await formsCreator.createEmptyFormData()

      if (createType === CREATE_TYPES.EXIST) {
        formsCreator.setPrevNumbers(evCisloFormulare, evCisloZakazky, kodSouvisFormulare)
        if (isRepair) {
          formsCreator.initRepair(repairType, tedNum)
        }
        await formsCreator.loadPrevAllData()
      }

      const formId = await formsCreator.createForms()
      closeModal()
      navigate(LINKS.vyhledatFormular + '/' + formId)
    } catch (e) {
      toast.error(getErrorMessage(e))
    } finally {
      setSentLoading(false)
    }
  }

  if (sentLoading) {
    return <Loading />
  }

  return (
    <>
      <div className='u-mb--50'>
        <RadioInput
          name={'createType'}
          value={createType}
          onChange={(e) => changeCreateType(e.target.value)}
          options={options}
        />

        {isMultiNewFormType && (
          <SelectInput
            label='Druh formuláře'
            name={'formType'}
            value={selectNewFormType}
            onChange={(e) => setSelectNewFormType(e.target.value)}
            options={optionsMultiFormType}
            required
          />
        )}

        {isRepair && hasFormRepairType && (
          <>
            {!formType || !optionsRepairType.length ? (
              <Loading />
            ) : (
              <SelectInput
                label='Odůvodnění opravy'
                name={'repairType'}
                value={repairType}
                onChange={(e) => setRepairType(e.target.value)}
                options={optionsRepairType}
                required
              />
            )}
          </>
        )}

        {isNeedTedNum && (
          <InputGov
            disabled={disableTextInput}
            name='tedNum'
            label={'Číslo oznámení v TED, které se má opravit'}
            value={tedNum}
            onChange={(e) => setTedNum(e.target.value)}
            description='formát 2023/S 005-008369'
            required
            error={tedNum && !isValidTedNum ? 'Nevalidní formát' : undefined}
          />
        )}

        {!isOnlyNew && (
          <>
            <InputGov
              disabled={disableTextInput}
              name='evZakazky'
              label={isOnlyAlterLabel ? 'Evidenční číslo zakázky/profilu' : 'Evidenční číslo zakázky'}
              value={evCisloZakazky}
              onChange={(e) => setEvCisloZakazky(e.target.value)}
              description='Zadejte evidenční číslo ve tvaru Z2022-123456 (popř. 6místné číslo pro zakázku uveřejněnou dle původní legislativy)'
              required
            />
            <InputGov
              disabled={disableTextInput}
              name='evForm'
              label={'Evidenční číslo formuláře'}
              value={evCisloFormulare}
              onChange={(e) => setEvCisloFormulare(e.target.value)}
              description='Zadejte evidenční číslo ve tvaru F2022-123456 (popř. 13místné číslo pro formulář uveřejněný dle původní legislativy)'
              required
            />
            <InputGov
              disabled={disableTextInput}
              name='kodForm'
              label={'Kód pro související formuláře'}
              value={kodSouvisFormulare}
              onChange={(e) => setKodSouvisFormulare(e.target.value)}
              description={'(Zadejte alfanumerický kód, který jste obdrželi v potvrzujicím emailu)'}
              required
            />
          </>
        )}
      </div>

      <ButtonGov variant='primary' className='u-me--20' onClick={clickCreate} disabled={!isFillAllInput}>
        Vytvořit
      </ButtonGov>
      <ButtonGov variant='primary-outlined' onClick={closeModal}>
        Zavřít
      </ButtonGov>
    </>
  )
}

export default ModalNewForm
