import { iPathMapping } from './pathsMappings'

export const pathsMappings1_8to1_13: iPathMapping[] = [
  {
    from: 'ND-Root.ND-ContractingParty.[].ND-ServiceProvider',
    to: 'ND-Root.ND-ContractingParty.[].ND-Buyer',
  },
  {
    from: 'ND-Root.ND-Lot.[].ND-LotTenderingProcess.BT-765-Lot',
    to: 'ND-Root.ND-Lot.[].ND-LotTenderingProcess.ND-LotFAContractingSystem.BT-765-Lot',
  },
  {
    from: 'ND-Root.ND-Lot.[].ND-LotTenderingProcess.BT-766-Lot',
    to: 'ND-Root.ND-Lot.[].ND-LotTenderingProcess.ND-LotDPSContractingSystem.BT-766-Lot',
  },
  {
    from: 'ND-Root.ND-Lot.[].ND-LotTenderingTerms.BT-736-Lot',
    to: 'ND-Root.ND-Lot.[].ND-LotTenderingTerms.ND-LotReservedExecution.BT-736-Lot',
  },
  {
    from: 'ND-Root.ND-Lot.[].ND-LotTenderingTerms.BT-743-Lot',
    to: 'ND-Root.ND-Lot.[].ND-LotTenderingTerms.ND-LotEInvoicing.BT-743-Lot',
  },
  {
    from: 'ND-Root.ND-Lot.[].ND-LotTenderingTerms.BT-744-Lot',
    to: 'ND-Root.ND-Lot.[].ND-LotTenderingTerms.ND-LotESignature.BT-744-Lot',
  },
  {
    from: 'ND-Root.ND-Lot.[].ND-LotTenderingTerms.BT-764-Lot',
    to: 'ND-Root.ND-Lot.[].ND-LotTenderingTerms.ND-LotECatalog.BT-764-Lot',
  },
  {
    from: 'ND-Root.ND-Lot.[].ND-LotTenderingTerms.OPT-060-Lot',
    to: 'ND-Root.ND-Lot.[].ND-ExecutionRequirements.OPT-060-Lot',
  },
  {
    from: 'ND-Root.ND-Part.[].ND-PartTenderingProcess.BT-765-Part',
    to: 'ND-Root.ND-Part.[].ND-PartTenderingProcess.ND-PartFAContractingSystem.BT-765-Part',
  },
  {
    from: 'ND-Root.ND-Part.[].ND-PartTenderingTerms.BT-736-Part',
    to: 'ND-Root.ND-Part.[].ND-PartTenderingTerms.ND-PartReservedExecution.BT-736-Part',
  },
  {
    from: 'ND-Root.ND-Lot.[].ND-LotTenderingTerms.ND-PostAwarProcess',
    to: 'ND-Root.ND-Lot.[].ND-LotTenderingTerms.ND-PostAwardProcess',
  },
  {
    from: 'ND-Root.ND-RootExtension.ND-NoticeResult.ND-LotTender.[].ND-SubcontractedActivity.BT-553-Tender',
    to: 'ND-Root.ND-RootExtension.ND-NoticeResult.ND-LotTender.[].ND-SubcontractedContract.BT-553-Tender',
  },
  {
    from: 'ND-Root.ND-RootExtension.ND-NoticeResult.ND-LotTender.[].ND-SubcontractedActivity.BT-554-Tender',
    to: 'ND-Root.ND-RootExtension.ND-NoticeResult.ND-LotTender.[].ND-SubcontractedContract.BT-554-Tender',
  },
  {
    from: 'ND-Root.ND-RootExtension.ND-NoticeResult.ND-LotTender.[].ND-SubcontractedActivity.BT-555-Tender',
    to: 'ND-Root.ND-RootExtension.ND-NoticeResult.ND-LotTender.[].ND-SubcontractedContract.BT-555-Tender',
  },
  {
    from: 'ND-Root.ND-RootExtension.ND-NoticeResult.ND-LotTender.[].ND-SubcontractedActivity.BT-730-Tender',
    to: 'ND-Root.ND-RootExtension.ND-NoticeResult.ND-LotTender.[].ND-SubcontractedContract.BT-730-Tender',
  },
  {
    from: 'ND-Root.ND-RootExtension.ND-NoticeResult.ND-LotTender.[].ND-SubcontractedActivity.BT-731-Tender',
    to: 'ND-Root.ND-RootExtension.ND-NoticeResult.ND-LotTender.[].ND-SubcontractedContract.BT-731-Tender',
  },
  {
    from: 'ND-Root.ND-Lot.[].ND-LotTenderingTerms.ND-SubcontractingObligation',
    to: 'ND-Root.ND-Lot.[].ND-LotTenderingTerms.ND-SubcontractingObligation.0',
  },
  {
    from: 'ND-Root.ND-Lot.[].ND-LotTenderingTerms.ND-SubcontractTerms.BT-64-Lot',
    to: 'ND-Root.ND-Lot.[].ND-LotTenderingTerms.ND-SubcontractingObligation.0.BT-64-Lot',
  },
  {
    from: 'ND-Root.ND-Lot.[].ND-LotTenderingTerms.ND-SubcontractTerms.BT-729-Lot',
    to: 'ND-Root.ND-Lot.[].ND-LotTenderingTerms.ND-SubcontractingObligation.0.BT-729-Lot',
  },
  {
    from: 'ND-Root.ND-ProcedureTenderingProcess.ND-DirectAward',
    to: 'ND-Root.ND-ProcedureTenderingProcess.ND-DirectAward.0',
  },
  {
    from: 'ND-Root.ND-Lot.[].ND-LotTenderingTerms.ND-NonUBLTenderingTerms.ND-SubcontractingIndication',
    to: 'ND-Root.ND-Lot.[].ND-LotTenderingTerms.ND-NonUBLTenderingTerms.ND-SubcontractingIndication.0',
  },
  {
    from: 'ND-Root.ND-RootExtension.ND-ContractModification.[].ND-Modification.0',
    to: 'ND-Root.ND-RootExtension.ND-ContractModification.[].ND-Modification',
  },
  {
    from: 'ND-Root.ND-RootExtension.ND-ContractModification.[].ND-Modification.0.ND-ModifiedSection.[].BT-1501(s)-Contract',
    to: 'ND-Root.ND-RootExtension.ND-ContractModification.[].ND-Modification.ND-ModifiedSection.[].BT-1501(p)-Contract',
  },
]
