export enum RULE_EFFECT {
  ENABLE = 'ENABLE',
  DISABLE = 'DISABLE',
  HIDE = 'HIDE',
  SHOW = 'SHOW',
}

interface iUISchemaRuleConditionLeaf {
  // definice podminky
  scope: string //  ("#/CONTRACTING_BODY/JOINT_PROCUREMENT_INVOLVED")
  schema: {
    //definice schema ktere odpovida podmince
    const?: any //  (true, false, "value1")
    type?: string | string[] // ("string", "number", ["object", "string"])
    enum?: string[] // multiConstant (['val1', 'val2])
  }
}

export interface iUISchemaRuleConditionAndOr {
  type: 'AND' | 'OR'
  conditions: iUISchemaRuleCondition[]
}

export type iUISchemaRuleCondition = iUISchemaRuleConditionAndOr | iUISchemaRuleConditionLeaf

export interface iUiSchemaRuleV1 {
  effect: RULE_EFFECT // akce pri splnene podmince (ENABLE, DISABLE, HIDE, VISIBLE)
  condition: iUISchemaRuleCondition
}
