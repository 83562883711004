import { ENV } from '../../ENV'
import { iSchemaDatasV1 } from '../interfaces/iSchemaDataV1'
import { convertDataNamespaces } from './convertDataNamespaces'
import { convertDataTypeLikeSchema } from './convertDataTypeLikeSchema'
import { convertXml2Json } from './convertXml2Json'

export const convertXmlWithSchema = (formRootEl: string, xmlFileContent: string, dataSchema: iSchemaDatasV1) => {
  const jsonData = convertXml2Json(xmlFileContent)
  const rootElement = Object.keys(jsonData)[1] // TODO co kdyz nema XML radek s definici
  // const rootElement = Object.keys(jsonData).find(elKey=>!elKey.startsWith('_'))
  const renameJsonData = convertDataNamespaces(jsonData[rootElement])
  const data = formRootEl ? renameJsonData[formRootEl] : renameJsonData
  const convertedData = convertDataTypeLikeSchema(data, dataSchema)
  if (ENV.DEBUG_MODE) {
    console.log('xmlFileContent', xmlFileContent)
    console.log('jsdonData', jsonData)
    console.log('renameJsonData', renameJsonData)
    console.log('dataSchema', dataSchema)
    console.log('convertedData', convertedData)
  }
  return convertedData
}
