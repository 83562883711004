import React, { useEffect } from 'react'
import { clearDynamEnum } from '../../redux/dynamEnum/dynamEnumSlice'
import { clearFormTree } from '../../redux/formTree/formTreeSlice'
import { useAppDispatch } from '../../redux/store'
import { FormRenderContexProvider } from '../context/RenderFormContext'
import { iDataSchemas } from '../interfaces/iDataSchema'
import { iUIschema } from '../interfaces/iUiSchema'
import RenderForm from '../renders/RenderForm'

interface iFormBaseSchemas {
  dataSchemas: iDataSchemas
  uiSchemas: iUIschema[]
  formSchemaId?: string
  validationMode?: string
}

const FormBaseSchemas = ({ uiSchemas, dataSchemas, formSchemaId, validationMode }: iFormBaseSchemas) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    return () => {
      dispatch(clearFormTree())
      dispatch(clearDynamEnum())
    }
  }, [])

  return (
    <FormRenderContexProvider
      dataSchemas={dataSchemas}
      uiSchemas={uiSchemas}
      helpSimple={true}
      shouldUnregisterDefault={true}
      formSchemaId={formSchemaId}
      validationMode={validationMode}
    >
      <RenderForm />
    </FormRenderContexProvider>
  )
}

export default FormBaseSchemas
