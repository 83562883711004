import convert from 'xml-js'
import { DELIMETER_NAMESPACE, DELIMETER_NAMESPACE_ALT } from '../specialChars'

const postprocesJson = (json: any): any => {
  if (typeof json === 'object' && !Array.isArray(json) && json !== null) {
    if (Object.keys(json).length === 1 && 'el_text_nest' in json) {
      return postprocesJson(json['el_text_nest'])
    }

    const obj: any = {}
    Object.entries(json).forEach(([key, value]) => {
      const prepared = postprocesJson(value)
      const aKey = key.replaceAll(DELIMETER_NAMESPACE, DELIMETER_NAMESPACE_ALT)
      obj[aKey] = prepared
    })
    return obj
  } else if (Array.isArray(json)) {
    return json.map(postprocesJson)
  } else {
    return json
      .replace(/_P_[^_]*_EL_START_/g, '')
      .replace(/_P_[^_]*_EL_END_/g, '\n')
      .replaceAll('_P_EL_NEWLINE_', '\n')
      .replace(/\n$/, '')
      .replaceAll('_FT_EL_START_', '<FT ')
      .replaceAll('_FT_EL_END_', '</FT>')
  }
}

export const convertXml2Json = (xml: any) => {
  var options = {
    compact: true,
    spaces: 2,
    textKey: 'el_text_nest',
    attributesKey: 'el_attrs',
    fullTagEmptyElement: false,
  }

  xml = xml
    .replace(/<P *xmlns="[^"]*" *>/g, '<P>') // P muze mit namespace jen ted pro cz formulare - ale vlozi se automaticky ze schematu
    .replace(/<([^>/]*:)?P *>/g, (find: string, namespace: string) => '_P_' + (namespace || '') + '_EL_START_')
    .replace(/<\/([^>]*:)?P *>/g, (find: string, namespace: string) => '_P_' + (namespace || '') + '_EL_END_')
    .replace(/<([^>/]*:)?P ?\/>/g, (find: string, namespace: string) => '_P_EL_NEWLINE_')
    .replace(/\s*_P_/g, '_P_')
    .replace(/_EL_END_\s*/g, '_EL_END_')
    .replaceAll('<FT ', '_FT_EL_START_')
    .replaceAll('</FT>', '_FT_EL_END_')
  //TODO muze mit FT namespace?

  let json = convert.xml2js(xml, options)
  json = postprocesJson(json)
  return json as any
}



