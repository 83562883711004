import { iSchemaDatasV1 } from '../interfaces/iSchemaDataV1'
import { iSchemaDataWithValid } from '../interfaces/iSchemaDataWithValid'
import { iSchemaValidationsV1 } from '../interfaces/iSchemaValidationV1'

export const convertDataSchemaMergeValidations = (
  schemaDataFull: iSchemaDatasV1,
  validations?: iSchemaValidationsV1,
): iSchemaDataWithValid => {
  if (validations === undefined) {
    return {
      type: 'object',
      properties: schemaDataFull,
      validations: undefined,
    }
  }

  const convertDataSchemaValidBlock = (schema: iSchemaDatasV1, scope: string) => {
    if (schema === undefined) {
      return undefined
    }
    const ret: any = {}
    for (const key in schema) {
      const thisScope = scope + '/' + key
      const valids = validations[thisScope] || undefined
      const schemaItem = schema[key]
      if (schemaItem.items?.properties) {
        const prop = convertDataSchemaValidBlock(schemaItem.items.properties, thisScope)
        ret[key] = {
          ...schemaItem,
          items: {
            ...schemaItem.items,
            properties: prop,
          },
          validations: valids,
        }
      } else if (schemaItem.properties) {
        const prop = convertDataSchemaValidBlock(schemaItem.properties, thisScope)
        ret[key] = {
          ...schemaItem,
          properties: prop,
          validations: valids,
        }
      } else {
        ret[key] = {
          ...schemaItem,
          validations: valids,
        }
      }
    }

    return ret
  }

  return {
    type: 'object',
    properties: convertDataSchemaValidBlock(schemaDataFull, '#'),
    validations: validations['#'],
  }
}
