import { ControlProps, OwnPropsOfEnum, rankWith, uiTypeIs } from '@jsonforms/core'
import { TranslateProps, withTranslateProps } from '@jsonforms/react'
import { useMemo } from 'react'
import { DATA_TYPE } from '../../../enums_v1/enumDataType'
import { changeLikeType } from '../../utils/changeLikeType'
import { withJsonFormsEnumPropsCustom } from '../../core/withJsonFormsEnumPropsCustom'
import DebugElement from '../../DebugElement'
import { getUiSchemeLikeType, iUIschemaV1 } from '../../interfaces/iUIschemaV1'
import InputGovLabel from '../../components/InputGovLabel'
import WrapInputGov from '../../components/WrapInputGov'
import { UiElTypesInput } from '../uiElTypes'

const GovInputRadioTrueFalse = (props: ControlProps & OwnPropsOfEnum & TranslateProps) => {
  const {
    data,
    handleChange,
    path,
    label,
    schema,
    uischema,
    id,
    errors,
    visible,
    enabled,
    t,
  } = props

  const uiOptions = (uischema as iUIschemaV1).options
  const radioTFInline = uiOptions?.radioTFInline
  const readOnly = uiOptions?.readOnly
  const disabled = !enabled || readOnly

  const { likeObject } = getUiSchemeLikeType(uischema)

  const schemaType = Array.isArray(schema.type) ? schema.type[0] : schema.type
  const isInteger = schemaType === DATA_TYPE.integer

  const change = (value: boolean | number) => {
    changeLikeType(value, uischema, path, handleChange)
  }

  const onClick = (value: boolean | number) => {
    if (!!data === !!value) {
      handleChange(path, undefined)
    }
  }

  const optionsTrueFalse = useMemo(
    () => [
      { label: t(`${path}.yes`, 'yes'), value: isInteger ? 1 : true },
      { label: t(`${path}.no`, 'no'), value: isInteger ? 0 : false },
    ],
    [t, path],
  )

  const style = radioTFInline ? { display: 'flex', gap: 30, paddingRight: 30 } : undefined

  if (!visible) {
    return null
  }

  return (
    <div id={uischema.scope} className='radioTrueFalse'>
      <DebugElement data={props} />
      <div style={style}>
        {label && (
          <WrapInputGov uischema={uischema} path={path} custom>
            <InputGovLabel inputId={''} label={label} noLeftPadding />
          </WrapInputGov>
        )}
        {optionsTrueFalse?.map(({ label, value }, index) => (
          <WrapInputGov key={index} custom error={errors}>
            <input
              id={id + '_' + value}
              type='radio'
              // jak vyresit pri nacteni to je undefined - kdyz je likeObject tak undefined je stav no ??
              checked={(data !== undefined || likeObject) && !!data === !!value}
              value={value.toString()}
              onChange={(e) => change(value)}
              disabled={disabled}
              className='gov-form-control__radio'
              onClick={() => onClick(value)}
              aria-disabled={disabled ? 'true' : 'false'}
              aria-labelledby={id + '_' + value}
            />
            <InputGovLabel inputId={id + '_' + value} label={label} withIndicator />
          </WrapInputGov>
        ))}
      </div>
    </div>
  )
}

export default withJsonFormsEnumPropsCustom(withTranslateProps(GovInputRadioTrueFalse))

export const govInputRadioTrueFalseTester = rankWith(3, uiTypeIs(UiElTypesInput.RadioTrueFalse))
