import get from 'lodash/get'
import { DATA_TYPE } from '../../enums_v1/enumDataType'
import { iSchemaDatasV1 } from '../interfaces/iSchemaDataV1'
import { iSchemaValidationsV1 } from '../interfaces/iSchemaValidationV1'
import { scopeToScopeWithRepeat } from './calculateDataSchemaArrayScopes'

export const calculateSchemaDefaultValues = (
  validations?: iSchemaValidationsV1,
  dataSchema?: iSchemaDatasV1,
  arrayScopes: string[] = [],
) => {
  if (!validations || !dataSchema) {
    return {}
  }
  const ret: { [scope: string]: any } = {}

  for (const scopeValid in validations) {
    const defVal = validations[scopeValid].defaultValue
    if (defVal !== undefined) {
      const { scopeWithRepeat, dataSchemaPath } = scopeToScopeWithRepeat(scopeValid, arrayScopes)

      const dataSchemaItem = get(dataSchema, dataSchemaPath)

      // TODO - az se opravi smazat - eforms BE uklada validace "{}" jako "[]"
      // nebo ulozit jako string a prevest do JSON az tady
      if (Array.isArray(defVal) && dataSchemaItem?.type === DATA_TYPE.object) {
        ret[scopeWithRepeat] = {}
      } else {
        ret[scopeWithRepeat] = defVal
      }
    }
  }
  return ret
}
