import React from 'react'
import { useElementContext } from '../context/ElementContext'
import { useFormTreeEl } from '../hooks/useFormTreeEl'
import { useElementErrors } from '../useElementErrors'

interface iSectionHeaderWrap {
  children: React.ReactNode
}

const SectionHeaderWrap = ({ children }: iSectionHeaderWrap) => {
  const formTreeEl = useFormTreeEl()
  const { uiSchema } = useElementContext()

  const label = uiSchema.label
  const errors = useElementErrors()

  return (
    <h2
      className={`${
        errors ? 'error-ref-element' : ''
      } custom-form__title gov-title--delta sticky-title sticky-title-level-${formTreeEl?.level} `}
      title={label || undefined}
    >
      {children}
    </h2>
  )
}

export default SectionHeaderWrap
