import React from 'react'
import { Link } from 'react-router-dom'
import AlertError from '../../components/Alert/AlertError'
import AlertInfo from '../../components/Alert/AlertInfo'
import { LINKS } from '../../components/App/LINKS'
import ButtonGov from '../../components/Btns/ButtonGov'
import Loading from '../../components/Loading/Loading'
import PaginatorPerPage from '../../components/PaginatorPerPage/PaginatorPerPage'
import { formTypeToCodeLabel } from '../../enums/enumFormTypeV2'
import { isFormDraft, WORKFLOW_PLACES_LABEL } from '../../enums/enumWorkflowPlaces'
import { useFetchFormsAdmin } from '../../hooks/useFetchFormsAdmin'
import { useModal } from '../../hooks/useModal'
import { iEformsSubmission, iSubmissionDataHlavni, iSubmissionDataProfil } from '../../interfaces/iEformsSubmission'
import { canAdminChangeWorkflow } from '../../utils/canAdminChangeWorkflow'
import { dateFormatFull } from '../../utils/dateFormat'
import SortTableHeader from '../components/sort/SortTableHeader'

const TableContractsAdmin = () => {
  const { data, loading, error, totalCount, maxItems } = useFetchFormsAdmin<
    iSubmissionDataHlavni[] | iSubmissionDataProfil[]
  >()

  const { openModalChangeWorkflow } = useModal()

  const openModal = (contract: iEformsSubmission<any>) => {
    openModalChangeWorkflow(contract)
  }

  if (error) {
    return <AlertError msg={error} />
  }
  if (loading) {
    return <Loading />
  }
  if (!data) {
    return null
  }

  return (
    <>
      <div className='gov-table-cover gov-table-cover--nowrap u-mb--50'>
        <table className='gov-table gov-sortable-table gov-table--mobile-block'>
          <thead>
            <tr>
              <th>
                <SortTableHeader name={'variableId'}>Ev. č. formuláře</SortTableHeader>
              </th>
              <th>
                <SortTableHeader name={'data.druhFormulare'}>Druh</SortTableHeader>
              </th>
              <th>
                <SortTableHeader name={'workflowPlace'}>Stav</SortTableHeader>
              </th>
              <th>
                <SortTableHeader name={'createdAt'}>Datum vytvoření</SortTableHeader>
              </th>
              {/*<th>*/}
              {/*  <SortTableHeader name={'updatedAt'}>Datum změny</SortTableHeader>*/}
              {/*</th>*/}
              <th>
                <SortTableHeader name={'data.datumPrijetiVvz'}>Datum přijetí</SortTableHeader>
              </th>
              <th>
                <SortTableHeader name={'data.lhutaUverejneniVvz'}>Lhůta VVZ</SortTableHeader>
              </th>
              <th>
                <SortTableHeader name={'data.lhutaUverejneniTed'}>Lhůta TED</SortTableHeader>
              </th>
              <th></th>
              <th></th>
              <th>Organizace</th>
              <th>Vlastník</th>
            </tr>
          </thead>
          <tbody className='gov-table__body'>
            {data?.map((form, index) => (
              <tr key={index}>
                <td>
                  <Link to={LINKS.admin + '/' + form.id}>
                    {isFormDraft(form.workflowPlaceCode) ? '-' : form.variableId}
                  </Link>
                </td>
                <td className='gov-table__cell--narrow'>{formTypeToCodeLabel(form.data.druhFormulare)}</td>
                <td>
                  {WORKFLOW_PLACES_LABEL[form.workflowPlaceCode] || form.workflowPlaceCode}
                  {(form.data?.formularZneplatnen || form.data?.formularOpravuje) && (
                    <small style={{ display: 'block' }}>
                      ({form.data?.formularZneplatnen ? 'zneplatněný' : form.data?.formularOpravuje ? 'opravný' : ''})
                    </small>
                  )}
                </td>
                <td className='gov-table__cell--date'>{dateFormatFull(form.createdAt)}</td>
                {/*<td className='gov-table__cell--date'>{dateFormatFull(form.updatedAt)}</td>*/}
                <td className='gov-table__cell--date'>{dateFormatFull(form.data.datumPrijetiVvz)}</td>
                <td className='gov-table__cell--date'>{dateFormatFull(form.data.lhutaUverejneniVvz)}</td>
                <td className='gov-table__cell--date'>{dateFormatFull((form.data as any).lhutaUverejneniTed)}</td>

                <td className='gov-table__cell--narrow'>
                  {canAdminChangeWorkflow(form) && <ButtonGov onClick={() => openModal(form)}>Změnit stav</ButtonGov>}
                </td>
                <td className='gov-table__cell--narrow u-align--right'>
                  <Link to={LINKS.admin + '/' + form.id} className='gov-link gov-link--has-arrow'>
                    Zobrazit
                  </Link>
                </td>
                <td className='gov-table__cell--narrow'>
                  <small>{form.organization.split('/').pop()}</small>
                </td>
                <td className='gov-table__cell--narrow'>
                  <small>{form.owner.name}</small>
                  <br />
                  <small>{form.owner.id}</small>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {!data?.length && <AlertInfo />}
      </div>
      <PaginatorPerPage totalCount={totalCount} maxItems={maxItems} />
    </>
  )
}

export default TableContractsAdmin
